export const bookingStatus = {
  seen: 'seen',
  canceled: 'canceled',
}

export const bookingConversationTypes =  {
  phone: 'phone',
  video:  'video',
  videoToPhone: 'videoToPhone',
}

export const testingClinicKey = 'telemed_triage'

export const vitalList = [
  { key: 'sysdia', name: 'BP' },
  { key: 'temperature', name: 'Temperature' },
  { key: 'spo2', name: 'SpO2' },
  { key: 'hstix', name: 'H\'stix' },
  { key: 'bodyweight', name: 'Body weight' },
  { key: 'imt', name: 'IMT' },
  { key: 'cigarette', name: 'Cigarette per day' },
  { key: 'aerobic', name: 'Aerobic exercise' },
  { key: 'pulse', name: 'Pulse' },
]

export const pageSize = 5

const constants = {
  bookingStatus,
  testingClinicKey,
  pageSize,
}

export default constants