import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import logo from '../../assets/logo.jpeg'
import {
  colors,
  Button,
  TextField,
  Grid,
  Box,
  Container,
  CircularProgress,
} from '@material-ui/core'
import actions from '../../store/actions'
import api from '../../api'

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(20),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '100%',
  },
  title: {
    fontWeight: 800,
    color: colors.grey[400],
  },

  center: {
    display: 'flex',
    alignItems: 'center',
  },
  titleName: {
    textAlign: 'center',
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#7bb5e0',
  },
}))

function AdminLogin({ userId, dispatch }) {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [loading, setLoading] = useState(false)

  if (userId) {
    history.push('/admin')
  }

  async function loginHandler(e) {
    e.preventDefault()
    setLoading(true)

    try {
      const { data } = await api.routes.auth.login(username, password)
      dispatch(actions.login(data.id, data.username, data.name, data.roles))
      history.push('/admin')
    } catch (err) {
      setLoginError(err.response.data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container component="div" className={classes.paper}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={6} className={classes.center}>
          <Grid container>
            {/* <Grid item xs={12}>
              <Box p={2}>
                <img
                  src={logo}
                  alt="logo"
                  className={classes.logo}
                ></img>
                
              </Box>
            </Grid> */}
            <Grid item xs={12} className={classes.titleName}>
              Online Clinics 線上診所
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box p={2}>
            <form className={classes.form} noValidate onSubmit={loginHandler}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
                onChange={e => {
                  setLoginError(false)
                  setUsername(e.target.value)
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={e => {
                  setLoginError(false)
                  setPassword(e.target.value)
                }}
                helperText={loginError ? 'Invalid Password' : ''}
                error={!!loginError}
              />
              {
                loading ? <CircularProgress /> :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >Login</Button>
              }
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default connect(state => ({
  userId: state.user.id,
}))(AdminLogin)