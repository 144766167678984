import getAxios from './axios'

const list = (year, month) => getAxios({
    url: `/summarys/bymonth/${year}/${month}`,
    method: 'GET',
})

const range = (from, to) => {
    const params = {
        from,
        to,
    }

    return getAxios({
        url: '/summarys/byrange',
        method: 'GET',
        params,
    })
}

const summarys = {
    list,
    range,
}

export default summarys