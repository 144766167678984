let stream = null

// A hook to maintenance a single stream instance
const useStream = () => {
  const getStream = () => stream

  const openStream = async () => {
    if (!stream) {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            width: { min: 640, ideal:1280, max: 1280 },
            height: { min: 480, ideal:720, max: 720 },
          },
        })
      } catch (err) {
        alert(`
          你所用的設備並沒有攝像鏡頭，或你並沒有允許使用！
          The device you are using does not have a camera or you do not allow to use it.
        `)
      }
    }
  }

  const closeStream = () => {
    if (stream) {
      stream.getTracks().forEach(t => t.stop())
      stream = null
    }
  }

  const muteAudio = (muted) => {
    if (!stream) {
      return
    }

    if (muted) {
      return stream.getTracks().map(t => t.kind === 'audio' && (t.enabled = false))
    }
    stream.getTracks().map(t => t.kind === 'audio' && (t.enabled = true))
  }

  return {
    getStream,
    openStream,
    muteAudio,
    closeStream,
  }
}

export default useStream