import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Card,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Slide,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'

import TemplateTable from '../VitalTemplates/TemplateTable'
import TemplateForm from '../VitalTemplates/TemplateForm'

import routes from '../../../api/routes'

function VitalTemplateList() {
    const [templates, setTemplates] = useState([])
    const [creatingTemplate, setCreatingTemplate] = useState(false)
    const [editingTemplate, setEditingTemplate] = useState(null)
    const shouldRefresh = useRef(true)

    const refresh = async () => {
        const { data } = await routes.vitalTemplates.list()
        setTemplates(data)
    }

    const deleteTemplateHandler = async (template) => {
        if (window.confirm('Confirm to remove vital template?')) {
            await routes.vitalTemplates.delete(template.id)
            refresh()
        }
    }

    const onTemplateEditClickHandler = (template) => {
        setEditingTemplate(template)
    }

    const onTemplateUpdateHandler = () => {
        closeTemplateDialog()
        refresh()
    }

    const onTemplateCreateHandler = () => {
        closeTemplateDialog()
        refresh()
    }

    const closeTemplateDialog = () => {
        setEditingTemplate(null)
        setCreatingTemplate(false)
    }

    useEffect(() => {
        if (shouldRefresh.current) {
            shouldRefresh.current = false
            refresh()
        }
    })

    return (
        <Box mt={3}>
            <Paper variant="outlined">
                <Box p={3}>
                    <Grid container spacing={3} alignContent="center">
                        <Grid item xs>
                            <Button 
                                color="inherit"
                                startIcon={<AddIcon />}
                                onClick={() => setCreatingTemplate(true)}
                            >Add Template</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs>
                    <Card vatiant="outlined">
                        <TemplateTable
                            templates={templates}
                            onDeleteClick={deleteTemplateHandler}
                            onTemplateEditClick={onTemplateEditClickHandler}
                        />
                    </Card>
                </Grid>
            </Grid>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={!!editingTemplate}
                onClose={() => setEditingTemplate(null)}
            >
                {editingTemplate && 
                    <>
                        <Box p={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => setEditingTemplate(null)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <TemplateForm
                                onCancelClick={() => setEditingTemplate(null)}
                                vitalTemplate={editingTemplate}
                                onTemplateUpdated={onTemplateUpdateHandler}
                            />
                        </Box>
                    </>
                }
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={creatingTemplate}
                onClose={() => setCreatingTemplate(false)}
            >
                {creatingTemplate && 
                    <>
                        <Box p={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => setCreatingTemplate(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <TemplateForm
                                onCancelClick={() => setCreatingTemplate(false)}
                                vitalTemplate={null}
                                onTemplateCreated={onTemplateCreateHandler}
                            />
                        </Box>
                    </>
                }
            </Dialog>
        </Box>
    )
}

export default VitalTemplateList