import React, { useEffect, useState, useRef } from 'react'
import dayjs from 'dayjs'
import {
  TableCell,
} from '@material-ui/core'

export default function PatientTableCounterCell(props) {
  const interval = useRef(null)
  const { connectedAt } = props
  const [waiting, setWaiting] = useState(calculateWaiting())

  function calculateWaiting() {
    const now = dayjs()
    const waitingMinute = (now.diff(connectedAt, 'minute') + '')
      .padStart(2, '0')
    const waitingSecond = (Math.abs(now.diff(connectedAt, 'second')) % 60 + '')
      .padStart(2, '0')

    return `${waitingMinute}:${waitingSecond}`
  }

  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setWaiting(calculateWaiting())
      }, 1000)
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
        interval.current = null
      }
    }
  })

  return <TableCell align="center">{waiting}</TableCell>
}