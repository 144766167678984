import React from 'react'
import {
    makeStyles,
    FormControl,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Box,
} from '@material-ui/core'

const useStyles = makeStyles({
    paper: ({ color }) => {
        return {
        borderStyle: 'dashed',
        borderColor: color,
        }
    },
    submit: ({ color }) => ({
        color: 'white',
        backgroundColor: color,
    }),
})

function GenderVerify({
    gender,
    setGender,
    color,
}) {
    const classes = useStyles({ color: color ? color : 'black' })
    return (
        <Grid item>
            <Box display="flex" alignItems="flex-end" height="100%">
            <FormControl component="div">
                <FormLabel
                component="legend"
                className={classes.radioLabel}
                >Gender 性別</FormLabel>
                <RadioGroup
                row
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                >
                <FormControlLabel
                    value="M"
                    control={<Radio
                    color="primary"
                    classes={{
                        root: classes.radio,
                        checked: classes.checked,
                    }}
                    />}
                    label="Male 男"
                ></FormControlLabel>
                <FormControlLabel
                    value="F"
                    control={<Radio
                    color="primary"
                    classes={{
                        root: classes.radio,
                        checked: classes.checked,
                    }}
                    />}
                    label="Female 女"
                ></FormControlLabel>
                </RadioGroup>
            </FormControl>
            </Box>
        </Grid>
    )
}

export default GenderVerify