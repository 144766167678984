import location from './location'

export const intents = {
  getHostCode: 'getHostCode',
  getIceServersConfig: 'getIceServersConfig',
  getPatientList: 'getPatientList',
  updatePatientList: 'updatePatientList',
  sendInvitation: 'sendInvitation',
  sendReply: 'sendReply',
  sendOffer: 'sendOffer',
  sendAnswer: 'sendAnswer',
  sendIceCandidate: 'sendIceCandidate',
  doneInspection: 'doneInspection',
  showSurvey: 'showSurvey',
  putOnHold: 'putOnHold',
  textMessage: 'textMessage',
}

const host = (roomKey, code) => {
  if (!roomKey) {
    throw new Error('No room key provided')
  }

  const url = location.socketURL(roomKey)

  if (code) {
    return url + `?code=${code}`
  }

  return url
}

const forwardMessage = (intent, sender = '', receiver = '', data = {}) => {
  return JSON.stringify({
    intent, sender, receiver, data,
  })
}

const newGetHostCodeIntent = () => {
  return forwardMessage(intents.getHostCode)
}

const newGetIceServersConfig = () => {
  return forwardMessage(intents.getIceServersConfig)
}

const newGetPatientListIntent = (sender) => {
  return forwardMessage(intents.getPatientList, sender, sender)
}

const newSendInvitationIntent = (sender, receiver) => {
  return forwardMessage(intents.sendInvitation, sender, receiver)
}

const newSendReplyIntent = (sender, receiver) => {
  return forwardMessage(intents.sendReply, sender, receiver)
}

const newSendOfferIntent = (sender, receiver, offer) => {
  return forwardMessage(intents.sendOffer, sender, receiver, {
    offer,
  })
}

const newSendAnswerIntent = (sender, receiver, answer) => {
  return forwardMessage(intents.sendAnswer, sender, receiver, {
    answer,
  })
}

const newSendIceCandidateIntent = (sender, receiver, iceCandidate) => {
  return forwardMessage(intents.sendIceCandidate, sender, receiver, {
    iceCandidate,
  })
}

const newPutOnHoldIntent = (sender, receiver) => {
  return forwardMessage(intents.putOnHold, sender, receiver)
}

const newDoneInspectionIntent = (sender, receiver) => {
  return forwardMessage(intents.doneInspection, sender, receiver)
}

const newShowSurveyIntent = (sender, receiver) => {
  return forwardMessage(intents.showSurvey, sender, receiver)
}

const newTextMessageIntent = (sender, receiver, message) => {
  return forwardMessage(intents.textMessage, sender, receiver, {
    message,
  })
}

const websocket = {
  intents,
  host,
  forwardMessage,
  newGetHostCodeIntent,
  newGetIceServersConfig,
  newGetPatientListIntent,
  newSendInvitationIntent,
  newSendReplyIntent,
  newSendOfferIntent,
  newSendAnswerIntent,
  newSendIceCandidateIntent,
  newPutOnHoldIntent,
  newDoneInspectionIntent,
  newShowSurveyIntent,
  newTextMessageIntent,
}

export default websocket