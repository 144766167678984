import { useState, useRef } from 'react'
import api from '../api'
import useWebSocket from './useWebSocket'

// A hook to maintain each peerConnection State
const usePeerConnection = () => {
  const peerConnection = useRef(null)
  const onConnectionStateChangeChannel = useRef({})
  const onIceConnectionStateChangeChannel = useRef({})
  const onNegotiationNeededChannel = useRef({})
  const [remoteStream, setRemoteStream]  = useState(null)
  const [sender, setSender] = useState('')
  const [receiver, setReceiver] = useState('')
  const {
    sendWebsocketMessage,
  } = useWebSocket()

  const getPeerConnection = () => peerConnection.current

  const broadcast = (channel, e) => {
    const subscribers = Object.keys(channel)

    subscribers.forEach(s => {
      if (channel[s]) {
        channel[s](e)
      }
    })
  }

  // on ice candidate
  const onIceCandidate = (e) => e.candidate && sendWebsocketMessage(api.ws.newSendIceCandidateIntent(sender, receiver, e.candidate))

  // on track
  const onTrack = (e) => setRemoteStream(e.streams[0])

  // on connection state change
  const onConnectionStateChange = (e) => broadcast(onConnectionStateChangeChannel.current, peerConnection.current.connectionState)
  const subscribeOnConnectionStateChange = (name, fn) => onConnectionStateChangeChannel.current[name] = fn
  const unsubscribeOnConnectionStateChange = (name) => delete onConnectionStateChangeChannel.current[name]

  // on ice connection state change
  const onIceConnectionStateChange = e => broadcast(onIceConnectionStateChangeChannel.current, peerConnection.current.iceConnectionState)
  const subscribeOnIceConnectionStateChange = (name, fn) => onIceConnectionStateChangeChannel.current[name] = fn
  const unsubscribeOnIceConnectionStateChange = name => delete onIceConnectionStateChangeChannel.current[name]

  // on negotiation needed
  const onNegotiationNeeded = () => broadcast(onNegotiationNeededChannel.current, true)
  const subscribeOnNegotiationNeeded = (name, fn) => onNegotiationNeededChannel.current[name] = fn
  const unsubscribeOnNegotiationNeeded = name => delete onNegotiationNeededChannel.current[name]

  const initPeerConnection = (iceServersConfig, stream, sender, receiver) =>  {
    if (!peerConnection.current) {
      peerConnection.current = new RTCPeerConnection(iceServersConfig)
      peerConnection.current.onicecandidate = onIceCandidate
      peerConnection.current.ontrack = onTrack
      peerConnection.current.onconnectionstatechange = onConnectionStateChange
      peerConnection.current.oniceconnectionstatechange = onIceConnectionStateChange
      peerConnection.current.onnegotiationneeded = onNegotiationNeeded

      stream.getTracks().forEach(track => {
        peerConnection.current.addTrack(track, stream)
      })

      setSender(sender)
      setReceiver(receiver)
    }
  }

  const closePeerConnection = () => {
    if (peerConnection.current) {
      peerConnection.current.close()
      peerConnection.current = null
      setSender('')
      setReceiver('')
    }
  }

  const setPeerConnectionStream = (stream) => {
    stream.getTracks().forEach(track => {
      peerConnection.current.addTrack(track, stream)
    })
  }

  const createAnswer = async (offer) => {
    await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer))

    const answer = await peerConnection.current.createAnswer({
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
    })

    await peerConnection.current.setLocalDescription(new RTCSessionDescription(answer))
    sendWebsocketMessage(api.ws.newSendAnswerIntent(sender, receiver, answer))
  }

  const addIceCandidate = (iceCandidate) => iceCandidate && peerConnection.current.addIceCandidate(new RTCIceCandidate(iceCandidate))

  if (peerConnection.current) {
    peerConnection.current.onicecandidate = onIceCandidate
    peerConnection.current.ontrack = onTrack
    peerConnection.current.onconnectionstatechange = onConnectionStateChange
  }

  return {
    getPeerConnection,
    remoteStream,
    initPeerConnection,
    closePeerConnection,
    setPeerConnectionStream,
    subscribeOnConnectionStateChange,
    unsubscribeOnConnectionStateChange,
    subscribeOnIceConnectionStateChange,
    unsubscribeOnIceConnectionStateChange,
    subscribeOnNegotiationNeeded,
    unsubscribeOnNegotiationNeeded,
    createAnswer,
    addIceCandidate,
  }
}

export default usePeerConnection