import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AppBar,
  makeStyles,
  Toolbar,
  Typography,
  colors,
  Avatar,
  IconButton,
  Button,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import logo from '../assets/logo.jpeg'
import MenuIcon from '@material-ui/icons/Menu'
import LanguageIcon from '@material-ui/icons/Language'

const useStyles = makeStyles((theme) => ({
  topBar: {
    color: 'black',
    backgroundColor: 'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: colors.grey[400],
    fontWeight: 'bold',
    marginLeft: 24,
  },
  logo: {
    paddingTop: 4,
    paddingBottom: 4,
    height: 48,
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    justifyContent: 'space-around',
  },
  welcomeMsg: {
    fontWeight: 'bold',
  },
}))

const Title = withWidth()((props) => {
  const classes = useStyles()

  if (isWidthUp('sm', props.width)) {
    return <Typography
      variant="h4"
      className={classes.title}
    >{props.name}</Typography>
  }

  return <Typography
    variant="h5"
    className={classes.title}
  >{props.name}</Typography>
})

function TopBar({onDrawerToggle, username, name, roles, dispatch}) {
  const location = useLocation()
  const classes = useStyles()
  const { i18n, t } = useTranslation()
  const shortName = useMemo(() => {
    const nameArr = name.split(' ')
    let shortName = ''
    nameArr.forEach(word => shortName += word[0])
    return shortName.toUpperCase()
  }, [name])

  function stringColor(str) {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    var c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase()

    return '#' + '00000'.substring(0, 6 - c.length) + c
  }

  const titleName = useMemo(() => {
    return t('title')
  }, [t])

  // Hide the top bar when is in the admin login page
  if (location.pathname === '/admin/login') {
    return false
  }

  return (
    <AppBar
      className={classes.topBar}
      position="sticky"
    >
      <Toolbar>
        { username &&
          <IconButton
            edge="start"
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        }
        {/* <img
          className={classes.logo}
          src={logo}
          alt="logo"
        ></img> */}
        <Title name={titleName}/>
        {
          username ? <>
            <div className={classes.infoSection}>
              <div>
                <Typography
                  className={classes.welcomeMsg}
                  variant="subtitle1"
                  align="right"
                >Welcome, {name}!</Typography>
              </div>
            </div>
            <Avatar
              style={{ backgroundColor: stringColor(name) }}
            >
              { shortName }
            </Avatar>
          </> : <></>
        }
        { (roles.length === 0 && username === '') &&
          <Button
            variant="outlined"
            startIcon={<LanguageIcon />}
            onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'cht' : 'en')}
          >ENG | 中</Button>
        }
      </Toolbar>
    </AppBar>
  )
}

export default connect(state => ({
  username: state.user.username,
  name: state.user.name,
  roles:  state.user.roles,
}))(TopBar)