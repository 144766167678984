import React, { useState, useRef, useEffect } from 'react'

import api from '../../api'
import {
  Card,
  Box,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  Toolbar,
  IconButton,
  Typography,
  AppBar,
  Button,
  LinearProgress,
  Chip,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import CloseIcon from '@material-ui/icons/Close'

export default function UserForm(props) {
  const {
    userId,
    onCloseClick,
    onUserCreated,
    onUserUpdated,
  } = props

  const initFetch = useRef(false)

  const [userData, setUserData] = useState(null)
  const [roomsData, setRoomsData] = useState([])
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [roles, setRoles] = useState([])
  const [errors, setErrors] = useState([])
  const [activated, setActivated] = useState(true)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [initializedForm, setInitializedForm] = useState(false)

  function rolesChangeHandler(e) {
    setRoles(e.target.value)
  }

  function validate(create) {
    const errors = []

    if (create && password !== confirmPassword) {
      errors.push('Password and confirm password are not the same')
    }

    if (!create && password && password !== confirmPassword) {
      errors.push('Password and confirm password are not the same')
    }

    if (username.length < 4) {
      errors.push('The length of username must greater than or equal 4')
    }

    if (name.length <= 3) {
      errors.push('The length of name must greater than 3')
    }

    if (
      (create && !password.length) ||
      (!create && password.length && password.length < 6)
    ) {
      errors.push('The password length must grater than or equal 6')
    }

    return errors
  }

  function onAssignedRoomsChange(e) {
    setSelectedRooms(e.target.value)
  }

  function onSubmitHandler(e) {
    e.preventDefault()

    setErrors([])

    const errors = validate(!userId)

    if (errors.length) {
      setErrors(errors)
      return
    }
    if (userId) {
      api.routes.users.update(userId, username, password, name, roles, activated)
        .then(({ data }) => {
          return api.routes.users.updateRooms(data.id, selectedRooms)
        }).then(() => {
          onUserCreated()
        }).catch(err => {
          setErrors(['Error occurs when creating a new user', err.response.data])
        })
    } else {
      api.routes.users.create(username, password, name, roles, activated)
        .then(({ data }) => {
          return api.routes.users.updateRooms(data.id, selectedRooms)
        }).then(() => {
          onUserUpdated()
        }).catch(err => {
          setErrors(['Error occurs when updating a user', err.response.data])
        })
    }
  }

  function init() {
    if (userData && !initializedForm) {
      setUsername(userData.username)
      setName(userData.name)
      setRoles(userData.roles)
      setActivated(userData.activated)
      setInitializedForm(true)
    }

    if (!initFetch.current) {
      initFetch.current = true

      setLoading(true)

      const promises = [
        api.routes.rooms.list(),
      ]

      if (userId) {
        promises.push(api.routes.users.show(userId))
        promises.push(api.routes.users.getRooms(userId))
      }

      Promise.all(promises).then(res => {
        setRoomsData(res[0].data)

        if (userId) {
          setUserData(res[1].data)
          setSelectedRooms(res[2].data.map(r => r.id))
        }
      }).catch(errs => {
        if (errs[0]) setErrors(['Error occurs when getting room list'])

        if (userId) {
          if (errs[1]) setErrors(['Error occurs when fetching the user'])
          if (errs[2]) setErrors(['Error occurs when getting user\'s room list'])
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    init()
  })

  return (
    <>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <Typography
            variant="h5"
            style={{ flex: '1 1 100%' }}
          >{userId ? 'Edit User' : 'Create User'}</Typography>
          <IconButton style={{ color: 'white' }} onClick={onCloseClick}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box p={3}>
        <Card>
          {(loading) && <LinearProgress />}
          <Box p={3}>
            <form onSubmit={onSubmitHandler}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    label="Username"
                    fullWidth
                    required
                    value={username}
                    disabled={loading || !!userId}
                    onChange={e => setUsername(e.target.value.toLowerCase())}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    required={!userId}
                    value={password}
                    disabled={loading}
                    onChange={e => setPassword(e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    required={!userId}
                    value={confirmPassword}
                    disabled={loading}
                    onChange={e => setConfirmPassword(e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Name"
                    fullWidth
                    required
                    value={name}
                    disabled={loading}
                    onChange={e => setName(e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="user-roles-label">Roles</InputLabel>
                    <Select
                      labelId="user-roles-label"
                      multiple
                      value={roles}
                      onChange={rolesChangeHandler}
                      renderValue={(selected) => selected.join(', ')}
                      disabled={loading}
                    >
                      <MenuItem value='super'>
                        <Checkbox checked={roles.indexOf('super') > -1} />
                        <ListItemText primary="Super" />
                      </MenuItem>
                      <MenuItem value='admin'>
                        <Checkbox checked={roles.indexOf('admin') > -1} />
                        <ListItemText primary="Admin" />
                      </MenuItem>
                      <MenuItem value='normal'>
                        <Checkbox checked={roles.indexOf('normal') > -1} />
                        <ListItemText primary="Normal" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" alignItems="end" height="100%">
                    <FormControlLabel
                      control={<Switch
                        checked={activated}
                        onChange={e => setActivated(e.target.checked)}
                        color="primary"
                      />}
                      label="Activated"
                      disabled={loading}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Assigned Clinics</InputLabel>
                    <Select
                      multiple
                      value={selectedRooms}
                      onChange={onAssignedRoomsChange}
                      renderValue={selected => roomsData && roomsData.map(room => {
                        if (selected.indexOf(room.id) >= 0) {
                          return <Chip key={room.name} label={room.name}></Chip>
                        }

                        return false
                      })}
                    >
                      {roomsData && roomsData.map(room => {
                        return <MenuItem value={room.id} name={room.name}>
                          <Checkbox checked={selectedRooms.indexOf(room.id) > -1} />
                          <ListItemText primary={room.name} />
                        </MenuItem>
                      })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                    >
                      {userId ? 'Update' : 'Create'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>

            <Box mt={3}>
              <Grid container spacing={1}>
                {errors.map(err => (
                  <Grid key={err} item xs={12}>
                    <Alert
                      // elevation={6}
                      variant="filled"
                      severity="error"
                    >{err}</Alert>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  )
}