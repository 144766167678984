import React, {useState} from 'react'
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  TextField,
  IconButton,
} from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'

import MessengerBody from './MessengerBody'

export default function Messenger(props) {
  const {
    sender,
    receiver,
    receiverName,
    messages,
    height,
    color,
    onSendMessage,
  } = props
  const [msg, setMsg] = useState('')

  function sendMessageHandler() {
    if (!msg) {
      return
    }

    onSendMessage(sender, receiver, msg)
    setMsg('')
  }

  return (
    <Card variant="outlined">
      { receiverName &&
        (
          <>
            <CardHeader
              title={receiverName}
            ></CardHeader>
            <Divider />
          </>
        )
      }
      <MessengerBody
        sender={sender}
        messages={messages}
        color={color}
        height={height ?? 200}
      />
      <Divider />
      <CardActions>
        <TextField
          fullWidth
          multiline
          rowsMax={1}
          value={msg}
          onChange={e => setMsg(e.target.value)}
        ></TextField>
        <IconButton
          variant="outlined"
          onClick={sendMessageHandler}
          style={{ color }}
        >
          <SendIcon />
        </IconButton>
      </CardActions>
    </Card>
  )
}