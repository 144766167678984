import getAxios from './axios'

const list = (materialGroupId, withCreator = false) => {
  const params = {}

  if (materialGroupId) {
    params['material_group_id'] = materialGroupId
  }

  if (withCreator) {
    params['creator'] = '1'
  }

  return getAxios({
    url: '/materials',
    method: 'GET',
    params,
  })
}

const create = (materialGroupId, type, title, content, sortOrder) => getAxios({
  url: '/materials',
  method: 'POST',
  data: {
    materialGroupId,
    type,
    title,
    content,
    sortOrder,
  },
})

const show = (id) => getAxios({
  url: `/materials/${id}`,
  method: 'GET',
})

const update = (id, data = {}) => getAxios({
  url: `/materials/${id}`,
  method: 'PATCH',
  data,
})

const destroy = (id) => getAxios({
  url: `/materials/${id}`,
  method: 'DELETE',
})

const uploadFile = (id, data) => getAxios({
  url: `/materials/${id}/file`,
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data,
})

const materials = {
  list,
  create,
  show,
  update,
  destroy,
  uploadFile,
}

export default materials