import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import axios from 'axios'
import {
  Grid,
  makeStyles,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core'
import DayjsUtils from '@date-io/dayjs'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'

import api from '../../api'
import utils from '../../utils'
import location from '../../api/location'
import {
  bookingStatus,
  bookingConversationTypes,
  vitalList,
} from '../../libs/constants'

import ColoredButton from '../ColoredButton'
import PatientQRCodeDialog from '../PatientQRCodeDialog'
import BookingVitalsDialog from './BookingVitalsDialog'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  textField: ({ color }) => ({
    '& label.Mui-focused': {
      color: color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: color,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: color,
      },
      '&:hover fieldset': {
        borderColor: color,
      },
      '&.Mui-focused fieldset': {
        borderColor: color,
      },
    },
  }),
  select: ({ color }) => ({
    borderColor: color,
    '&:focus': {
      borderColor: color,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: color,
    },
    '& label.Mui-focused': {
      color: color,
    },
  }),
  margin: {
    margin: theme.spacing(3),
  },
  inline: {
    display: 'inline',
  },
}))

function BookingForm(props) {
  const { room, onBookingCreated, onBookingUpdated, user } = props
  const classes = useStyles({ color: room.color })

  const { register, handleSubmit, errors, control, reset, setValue, watch } = useForm({
    reValidateMode: 'onSubmit',
  })
  const [booking, setBooking] = useState(props.booking ? {
    sopd: props.booking.sopd,
    hkid: props.booking.hkid_partial,
    name: props.booking.name,
    chinese: props.booking.chinese,
    gender: props.booking.gender,
    dob: props.booking.dob,
    time: props.booking.time,
    status: props.booking.status,
    conversationType: props.booking.conversationType,
    phone: props.booking.phone,
    operation: props.booking.operation,
    otDate: props.booking.otDate,
    referFrom: props.booking.referFrom,
    diagnosis: props.booking.diagnosis,
    selectedCancelReason: props.booking.selectedCancelReason ?? (props.booking.cancelReason ? -1 : ''),
    cancelReason: props.booking.cancelReason,
  } : {
    sopd: '',
    hkid: '',
    name: '',
    chinese: '',
    gender: '',
    dob: dayjs('1960-01-01'),
    time: dayjs().add(1, 'd'),
    status: '',
    conversationType: 'phone',
    phone: '',
    operation: '',
    otDate: '',
    referFrom: '',
    diagnosis: '',
    selectedCancelReason: '',
    cancelReason: '',
  })
  const cancelReasonWatch = watch('selectedCancelReason')

  const [remarks, setRemarks] = useState([])

  const [selectedVital, setSelectedVital] = useState(-1)
  const [editing, setEditing] = useState(false)
  const [editVital, setEditVital] = useState(null)
  const [vitals, setVitals] = useState([])

  const [cancelReasons, setCancelReasons] = useState([])
  const [vitalTemplates, setVitalTemplates] = useState([])

  const [updatingRemarks, setUpdatingRemarks] = useState(false)
  const isUpdate = !!props.booking
  const initRemarks = useRef(false)
  const initReasons = useRef(false)
  const initTemplates = useRef(false)
  const templateFetched = useRef(false)
  const initVitals = useRef(false)

  const checkingRegex = new RegExp(utils.getInputFieldRegez(), 'g')

  async function onSubmitHandler(data) {
    setUpdatingRemarks(true)
    for (let i = 0; i < remarks.length; i++) {
      if (!remarks[i].deleted && !remarks[i].content) {
        setUpdatingRemarks(false)
        alert('Remark content is empty, please check.')
        return
      }
    }

    let existVitals = []
    for (let i = 0; i < vitals.length; i++) {
      if (!vitals[i].deleted) {
        if (existVitals.includes(vitals[i].selectedVital)) {
          setUpdatingRemarks(false)
          alert('Vital instruction duplicated, please check.')
          return
        } else {
          existVitals.push(vitals[i].selectedVital)
        }
        if (!vitals[i].selectedTemplate || !vitals[i].selectedVital) {
          setUpdatingRemarks(false)
          alert('Some contents in vital is missing, please check.')
          return
        }
      }
    }

    if (data.hkid.length !== 4) {
      setUpdatingRemarks(false)
      alert('HKID should have 4 digits, please check.')
      return
    }

    const time = data.bookingDate
      .set('hour', data.bookingTime.split(':')[0])
      .set('minute', data.bookingTime.split(':')[1])
      .set('second', 0)
      .set('millisecond', 0)

    try {
      if (isUpdate) {
        const booking = await api.routes.bookings.update(props.booking.id, {
          sopd: data.sopd,
          name: data.name,
          chinese: data.chinese,
          hkid: data.hkid,
          gender: data.gender,
          dob: data.dob.format('YYYY-MM-DD'),
          time: time.toISOString(),
          remarks: remarks,
          status: data.status,
          selectedCancelReason: (data.selectedCancelReason > 0) ? data.selectedCancelReason : 0,
          cancelReason: (data.selectedCancelReason === -1) ? data.cancelReason : '',
          referFrom: data.referFrom,
          otDate: data.otDate,
          diagnosis: data.diagnosis,
          operation: data.operation,
          phone: data.phone,
          conversationType: data.conversationType,
        })

        await saveRemarks(booking.data.id)
        await saveVitals(booking.data.id)

        onBookingUpdated && onBookingUpdated(booking.data)
      } else {
        const otDate =  data.otDate?.format('YYYY-MM-DD')

        const booking = await api.routes.bookings.create(
          room.id,
          data.sopd,
          data.hkid,
          data.name,
          data.chinese,
          data.gender,
          data.dob.format('YYYY-MM-DD'),
          time.toISOString(),
          otDate,
          data.diagnosis,
          data.operation,
          data.referFrom,
          data.phone,
          data.conversationType,
        )

        await saveRemarks(booking.data.id)
        await saveVitals(booking.data.id)
        await setBooking(booking.data)
        onBookingCreated && onBookingCreated(booking.data)
      }
    } catch (err) {
      alert('Error occurs when saving the booking')
    } finally {
      setUpdatingRemarks(false)
    }
  }

  async function saveRemarks(bookingId) {
    const updatePromises = []
    remarks.forEach(remark => {
      if (!remark.updated && !remark.deleted) {
        return
      }

      // Delete a remark
      if (remark.id && remark.deleted) {
        return updatePromises.push(axios.request({
          ...api.routes.bookingRemarks.delete(props.booking.id, remark.id),
          baseURL: location.backendURL(),
          withCredentials: true,
        }))
      }

      // Update a remark
      if (remark.id && remark.updated) {
        updatePromises.push(axios.request({
          ...api.routes.bookingRemarks.update(
            bookingId,
            remark.id,
            remark.userId,
            remark.username,
            remark.content,
          ),
          baseURL: location.backendURL(),
          withCredentials: true,
        }))
      }

      // Create a remark
      if (!remark.id && remark.updated) {
        updatePromises.push(axios.request({
          ...api.routes.bookingRemarks.create(
            bookingId,
            remark.userId,
            remark.username,
            remark.content,
          ),
          baseURL: location.backendURL(),
          withCredentials: true,
        }))
      }
    })

    await Promise.all(updatePromises)
  }

  async function saveVitals(bookingId) {
    const updatePromises = []

    vitals.forEach(vital => {
      if (!vital.updated && !vital.deleted) {
        return
      }

      // Delete a vital
      if (vital.id && vital.deleted) {
        return updatePromises.push(axios.request({
          ...api.routes.bookingVitals.delete(bookingId, vital.id),
          baseURL: location.backendURL(),
          withCredentials: true,
        }))
      }

      // Update a vital
      if (vital.id && vital.updated) {
        updatePromises.push(axios.request({
          ...api.routes.bookingVitals.update(
            bookingId,
            vital.id,
            vital.userId,
            vital.username,
            vital.selectedTemplate,
            vital.selectedVital,
            vital.values[0],
            vital.values[1],
            vital.values[2],
            vital.values[3],
          ),
          baseURL: location.backendURL(),
          withCredentials: true,
        }))
      }

      // Create a vital
      if (!vital.id && vital.updated) {
        updatePromises.push(axios.request({
          ...api.routes.bookingVitals.create(
            bookingId,
            vital.userId,
            vital.username,
            vital.selectedTemplate,
            vital.selectedVital,
            vital.values[0],
            vital.values[1],
            vital.values[2],
            vital.values[3],
          ),
          baseURL: location.backendURL(),
          withCredentials: true,
        }))
      }
    })

    await Promise.all(updatePromises)
  }

  function nextBookingHandler() {
    reset({})
    setBooking({
      sopd: '',
      name: '',
      chinese: '',
      gender: '',
      dob: dayjs('1960-01-01'),
      time: dayjs().add(1, 'd'),
      status: '',
      conversationType: 'phone',
      phone: '',
      operation: '',
      otDate: '',
      referFrom: '',
      diagnosis: '',
      selectedCancelReason: -1,
      cancelReason: '',
    })
    setRemarks([])
    setVitals([])
  }

  function addRemarks() {
    setRemarks([
      ...remarks,
      {
        content: '',
        userId: user.id,
        userName: user.name,
      },
    ])
  }

  function updateRemarks(index, event) {
    const newRemarks = [...remarks]
    const currentRemark = newRemarks[index]

    currentRemark.content = event.target.value
    currentRemark.updated = true
    currentRemark.userName = user.name
    currentRemark.userId = user.id

    setRemarks(newRemarks)
  }

  async function removeRemark(index) {
    setRemarks(remarks.map((r, i) => {
      if (i === index) {
        r.deleted = true
      }

      return r
    }))
  }


  function getVitalTemplateContent(templateId) {
    const currentTemplate = vitalTemplates.find(x => x.id === templateId)

    const chinese = currentTemplate.chineseContent.split(checkingRegex)
    const english = currentTemplate.englishContent.split(checkingRegex)
    const chineseinput = currentTemplate.chineseContent.match(checkingRegex)
    const englishinput = currentTemplate.englishContent.match(checkingRegex)

    let chineseFinal = []
    let englishFinal = []
    for (let i = 0; i < chinese.length; i++) {
      chineseFinal.push(chinese[i])
      if (i < chineseinput.length) {
        chineseFinal.push(chineseinput[i])
      }
    }

    for (let i = 0; i < english.length; i++) {
      englishFinal.push(english[i])
      if (i < englishinput.length) {
        englishFinal.push(englishinput[i])
      }
    }

    return {
      chinese: chineseFinal,
      english: englishFinal,
    }
  }

  function addVitals() {
    setEditVital({
      selectedTemplate: '',
      selectedVital: '',
      englishContent: [],
      chineseContent: [],
      values: ['', '', '', ''],
      userId: user.id,
      userName: user.name,
    })
    setEditing(true)
    setSelectedVital(-1)
  }

  function selectVitalForEditing(index) {
    setSelectedVital(index)
    const selectedVital = vitals[index]
    setEditVital(selectedVital)
    setEditing(true)
  }

  function updateVitalTemplate(event) {
      const currentVital = {...editVital}

      currentVital.selectedTemplate = event.target.value
      let currentContent = getVitalTemplateContent(event.target.value)

      currentVital.chineseContent = currentContent.chinese
      currentVital.englishContent = currentContent.english
      currentVital.updated = true

      setEditVital(currentVital)
  }

  function updateVitalField(event) {
    const currentVital = {...editVital}

    currentVital.selectedVital = event.target.value
    currentVital.updated = true

    setEditVital(currentVital)
  }

  function updateVitalValue(event, index) {
    const currentVital = {...editVital}

    currentVital.values[index] = event.target.value
    currentVital.updated = true

    setEditVital(currentVital)
  }

  function cancelVitalChange() {
    setEditVital(null)
    setEditing(false)
  }

  function confirmVitalChange() {
    if (!editVital.selectedTemplate) {
      alert('Template is missing, please check.')
      return
    }

    if (!editVital.selectedVital) {
      alert('Vital is missing, please check.')
      return
    }

    for (let i = 0; i < vitals.length; i++) {
      if (selectedVital !== i && vitals[i].selectedVital === editVital.selectedVital) {
        alert('Vital is duplicated, please check.')
        return
      }
    }

    if (selectedVital === -1) {
      const current = [...vitals]
      current.push(editVital)
      setVitals(current)
    } else {
      const current = [...vitals]
      current[selectedVital] = { ...editVital }
      setVitals(current)
    }

    setEditVital(null)
    setEditing(false)
  }

  async function removeVitals(index) {
    setVitals(vitals.map((r, i) => {
      if (i === index) {
        r.deleted = true
      }

      return r
    }))
  }

  const clearOTDate = e => {
    e.stopPropagation()
    setValue('otDate', null)
  }

  function fetchRemarks() {
    if (isUpdate && !initRemarks.current) {
      initRemarks.current = true

      api.routes.bookingRemarks.list(props.booking.id).then(res => {
        setRemarks(res.data)
      }).catch(err => {
        alert('Error occurs when getting booking remarks')
      })
    }
  }

  function fetchCancelReason() {
    if (isUpdate && !initReasons.current) {
      initReasons.current = true

      api.routes.cancelReasons.list().then(res => {
        let list = res.data
        if (props.booking && props.booking.selectedCancelReason) {
          let currentReason = list.find(x => x.id === props.booking.selectedCancelReason)
          // fetch a single cancel reason data only if the selected one is deleted 
          if (!currentReason) {
            api.routes.cancelReasons.get(props.booking.selectedCancelReason).then(res2 => {
              list = [...list, res2.data]
              setCancelReasons(list)
            }).catch(err => {
              setCancelReasons(list)
              alert('Error occurs when getting cancel reason')
            })
          } else {
            setCancelReasons(res.data)
          }
        } else {
          setCancelReasons(res.data)
        }        
      }).catch(err => {
        alert('Error occurs when getting cancel reasons')
      })
    }
  }

  function fetchVitalTemplates() {
    if (!initTemplates.current) {
      initTemplates.current = true

      api.routes.vitalTemplates.list().then(res => {
        setVitalTemplates(res.data)
        templateFetched.current = true
      }).catch(err => {
        alert('Error occurs when getting vital templates')
      })
    }
  }

  function fetchBookingVital() {
    if (isUpdate && !initVitals.current && templateFetched.current) {
      initVitals.current = true

      api.routes.bookingVitals.list(props.booking.id).then(res => {
        let tempVitals = []
        res.data.forEach(vital => {

          const content = getVitalTemplateContent(vital.templateId)
          tempVitals.push({
            id: vital.id,
            selectedTemplate: vital.templateId,
            selectedVital: vital.vital,
            englishContent: content.english,
            chineseContent: content.chinese,
            values: [vital.value1, vital.value2, vital.value3, vital.value4],
            userId: vital.userId,
            userName: vital.userName,
          })
        })

        setVitals(tempVitals)
      }).catch(err => {
        alert('Error occurs when getting booking vital')
      })
    }
  }

  useEffect(() => {
    fetchRemarks()
    fetchCancelReason()
    fetchVitalTemplates()
    fetchBookingVital() 
  })

  return (
    <>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {props.booking?.creator &&
                  <Grid item xs>Created At {dayjs(props.booking.createdAt).format('YYYY-MM-DD HH:mm')} by {props.booking.creator.name}</Grid>
                }
                {props.booking?.editor &&
                  <Grid item xs>
                    <span>Edited At {dayjs(props.booking.updatedAt).format('YYYY-MM-DD HH:mm')} by {props.booking.editor.name}</span>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="sopd"
                defaultValue={booking.sopd}
                render={(props) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="SOPD"
                    className={classes.textField}
                    error={!!errors.sopd}
                    disabled={updatingRemarks}
                    onChange={ (e) => {
                      props.onChange(e.target.value.toUpperCase())
                    }}
                    value={props.value}
                  ></TextField>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="hkid"
                defaultValue={booking.hkid}
                as={
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="number"
                    label="HKID First 4 Digit"
                    className={classes.textField}
                    error={!!errors.hkid}
                    disabled={updatingRemarks}
                  ></TextField>
                }
              >
              </Controller>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="name"
                defaultValue={booking.name}
                render={(props) => (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Patient Name"
                    className={classes.textField}
                    error={!!errors.name}
                    disabled={updatingRemarks}
                    onChange={ (e) => {
                      props.onChange(e.target.value.toUpperCase())
                    }}
                    value={props.value}
                  ></TextField>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <Controller
                control={control}
                rules={{ required: false }}
                name="chinese"
                defaultValue={booking.chinese}
                as={
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Chinese Name (Optional)"
                    className={classes.textField}
                    error={!!errors.chinese}
                    disabled={updatingRemarks}
                  ></TextField>
                }
              ></Controller>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl margin="normal" fullWidth variant="outlined" className={classes.select}>
                <InputLabel>Gender</InputLabel>
                <Controller
                  control={control}
                  name="gender"
                  defaultValue={booking.gender}
                  rules={{ required: true }}
                  as={
                    <Select
                      label="Gender"
                      error={!!errors.gender}
                      disabled={updatingRemarks}
                    >
                      <MenuItem value="m">Male</MenuItem>
                      <MenuItem value="f">Female</MenuItem>
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                as={
                  <KeyboardDatePicker
                    fullWidth
                    autoOk
                    margin="normal"
                    inputVariant="outlined"
                    variant="inline"
                    format="YYYY-MM-DD"
                    label="Patient DoB"
                    className={classes.select}
                    openTo="year"
                    disabled={updatingRemarks}
                  />
                }
                control={control}
                name="dob"
                placeholder="Patient DoB"
                defaultValue={booking.dob ? dayjs(booking.dob, 'YYYY-MM-DD') : dayjs('1960-01-01', 'YYYY-MM-DD')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                as={
                  <KeyboardDatePicker
                    fullWidth
                    autoOk
                    margin="normal"
                    inputVariant="outlined"
                    variant="inline"
                    format="YYYY-MM-DD"
                    label="Appointment Date"
                    className={classes.select}
                    disabled={updatingRemarks}
                  />
                }
                control={control}
                name="bookingDate"
                placeholder="Appointment Date"
                defaultValue={isUpdate ? dayjs(booking.time) : dayjs().add(1, 'day')}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                name="bookingTime"
                margin="normal"
                variant="outlined"
                label="Appointment Time"
                type="time"
                defaultValue={isUpdate ? dayjs(booking.time).format('HH:mm') : '09:00'}
                inputRef={register}
                disabled={updatingRemarks}
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl margin="normal" fullWidth variant="outlined" className={classes.select}>
                <InputLabel >Conversation Type</InputLabel>
                <Controller
                  control={control}
                  name="conversationType"
                  defaultValue={booking.conversationType || 'phone'}
                  rules={{ required: true }}
                  as={
                    <Select
                      label="Conversation Type"
                      error={!!errors.conversationType}
                      disabled={updatingRemarks}
                    >
                      <MenuItem value={bookingConversationTypes.phone}>Phone</MenuItem>
                      <MenuItem value={bookingConversationTypes.video}>Video</MenuItem>
                      <MenuItem value={bookingConversationTypes.videoToPhone}>Video To Phone</MenuItem>
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                rules={{ required: false }}
                name="phone"
                defaultValue={booking.phone || ''}
                as={
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                    className={classes.textField}
                    error={!!errors.phone}
                    disabled={updatingRemarks}
                  ></TextField>
                }
              ></Controller>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{ required: false }}
                name="diagnosis"
                defaultValue={booking.diagnosis || ''}
                as={
                  <TextField
                    multiline
                    rowsMax="3"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Diagnosis"
                    className={classes.textField}
                    error={!!errors.diagnosis}
                    disabled={updatingRemarks}
                  ></TextField>
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                control={control}
                rules={{ required: false }}
                name="operation"
                defaultValue={booking.operation || ''}
                as={
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Operation"
                    className={classes.textField}
                    error={!!errors.operation}
                    disabled={updatingRemarks}
                  ></TextField>
                }
              ></Controller>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                as={
                  <KeyboardDatePicker
                    fullWidth
                    autoOk
                    margin="normal"
                    inputVariant="outlined"
                    variant="inline"
                    format="YYYY-MM-DD"
                    label="OT Date"
                    className={classes.select}
                    disabled={updatingRemarks}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <CloseIcon onClick={clearOTDate}/>
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                control={control}
                name="otDate"
                placeholder="OT Date"
                defaultValue={isUpdate && booking.otDate ? dayjs(booking.otDate) : null}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl margin="normal" fullWidth variant="outlined" className={classes.select}>
                <InputLabel >Refer From</InputLabel>
                <Controller
                  control={control}
                  name="referFrom"
                  defaultValue={booking.referFrom || ''}
                  rules={{ required: false }}
                  as={
                    <Select
                      label="Refer From"
                      error={!!errors.referFrom}
                      disabled={updatingRemarks}
                    >
                      <MenuItem value="anae">Anae</MenuItem>
                      <MenuItem value="npac">NPAC</MenuItem>
                      <MenuItem value="physio">Physio</MenuItem>
                      <MenuItem value="surgeryschool">Surgery School</MenuItem>
                      <MenuItem value="cpex">CPEX</MenuItem>
                      <MenuItem value="ornc">OPNC</MenuItem>
                      <MenuItem value="arac">POMC</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  }
                ></Controller>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    margin="normal"
                    fullWidth
                    variant="contained"
                    disabled={updatingRemarks}
                    onClick={addVitals}
                  >Add Vitals</Button>
                </Grid>
                <Grid item xs>
                </Grid>
              </Grid>
            </Grid>

            {vitals.map((vital, i) => {
              if (vital.deleted) {
                return false
              }

              return (
                <Grid key={'vitalinsrtruction' + i} fullWidth margin="normal" xs={12} md={6} lg={6}>
                  <Box justifyContent="center" alignItems="center">
                    <Grid container xs={12} md={12} lg={12}>
                      <Card variant="outlined" style={{ width: '100%'}}>
                        <CardContent>
                          <Typography variant="h5" component="h2">
                            { vitalList.find(s => s.key === vital.selectedVital)?.name }
                          </Typography>
                          <Typography variant="h5" component="h2">
                            { vital.chineseContent.length !== 0 && 
                              vital.chineseContent.map((content, x) => {
                                if (checkingRegex.test(content)) {
                                  let currentIndex = parseInt(content.replace(/\D/g,'')) - 1
                                  return (
                                    <span className={classes.inline}>{vital.values[currentIndex]}</span>
                                  )
                                }
                                return (
                                  <span className={classes.inline}>{content}</span>
                                )
                              })
                            }
                          </Typography>
                          <Typography variant="h5" component="h2">
                            { vital.englishContent.length !== 0 && 
                              vital.englishContent.map((content, x) => {
                                if (checkingRegex.test(content)) {
                                  let currentIndex = parseInt(content.replace(/\D/g,'')) - 1
                                  return (
                                    <span className={classes.inline}>{vital.values[currentIndex]}</span>
                                  )
                                }
                                return (
                                  <span className={classes.inline}>{content}</span>
                                )
                              })
                            }
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Grid item>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <Button
                                  size="large"
                                  color="primary"
                                  margin="normal"
                                  fullWidth
                                  variant="contained"
                                  disabled={updatingRemarks}
                                  className={classes.margin}
                                  onClick={()=>selectVitalForEditing(i)}
                                >Edit</Button>
                              </Grid>
                              <Grid item xs>
                                <Button
                                  size="large"
                                  color="secondary"
                                  margin="normal"
                                  fullWidth
                                  variant="contained"
                                  disabled={updatingRemarks}
                                  className={classes.margin}
                                  onClick={()=>removeVitals(i)}
                                >Remove</Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Box>
                </Grid>
              )
            })}

            {!!props.booking &&
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Controller
                    control={control}
                    rules={{ required: false }}
                    name="status"
                    defaultValue={booking.status || ''}
                    as={
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Status"
                        className={classes.textField}
                        error={!!errors.status}
                        disabled={updatingRemarks}
                      >
                        <MenuItem value="">-</MenuItem>
                        <MenuItem value={bookingStatus.seen}>Seen</MenuItem>
                        <MenuItem value={bookingStatus.canceled}>Canceled</MenuItem>
                      </TextField>
                    }
                  ></Controller>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Controller
                    control={control}
                    rules={{ required: false }}
                    name="selectedCancelReason"
                    defaultValue={booking.selectedCancelReason || ''}
                    as={
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Cancel Reason"
                        className={classes.textField}
                        error={!!errors.selectedCancelReason}
                        disabled={updatingRemarks}
                      >
                        <MenuItem key={'none'} value={''}>-</MenuItem>
                        {cancelReasons.map((reason, i) => {
                          if (reason.deleted) {
                            return false
                          }

                          return (
                            <MenuItem key={reason.id} value={reason.id}>{reason.content}</MenuItem>
                          )
                        })}
                        <MenuItem key={-1} value={-1}>Others</MenuItem>
                      </TextField>
                    }
                  ></Controller>
                  </Grid>
                  {
                    cancelReasonWatch === -1 && 
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                          <Controller
                            control={control}
                            rules={{ require: false }}
                            name="cancelReason"
                            defaultValue={booking.cancelReason}
                            as={
                              <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Please specify"
                                className={classes.textField}
                                error={!!errors.cancelReason}
                                disabled={updatingRemarks}
                              ></TextField>
                            }
                          >
                          </Controller>
                        </Grid>
                    </>
                  }
              </>
            }
            {remarks.map((remark, i) => {
              if (remark.deleted) {
                return false
              }

              return (
                <Grid key={'remark' + i} item xs={12}>
                  <TextField
                    label="Remark"
                    multiline
                    fullWidth
                    rowsMax={3}
                    variant="outlined"
                    value={remark.content}
                    helperText={`Edited by: ${remark.userName} - ${dayjs(remark.updatedAt).format('YYYY-MM-DD HH:mm:ss')}`}
                    className={classes.textField}
                    onChange={e => updateRemarks(i, e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => removeRemark(i)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
              )
            })}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Button
                    color="primary"
                    margin="normal"
                    fullWidth
                    variant="contained"
                    disabled={updatingRemarks}
                    onClick={addRemarks}
                  >Add Remark</Button>
                </Grid>
                <Grid item xs>
                  <ColoredButton
                    color={room.color}
                    textColor="white"
                    margin="normal"
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={updatingRemarks}
                  >{!!props.booking ? 'Update' : 'Register'}</ColoredButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>

      {!!props.booking ? false :
        (booking.id &&
          <PatientQRCodeDialog
            booking={booking}
            onClose={nextBookingHandler}
          >
            <ColoredButton
              color={room.color}
              textColor="white"
              margin="normal"
              fullWidth
              variant="contained"
              onClick={nextBookingHandler}
            >Next Booking</ColoredButton>
          </PatientQRCodeDialog>
        )
      }

      {editing && 
        <Dialog
          fullWidth
          maxWidth="lg"
          scroll="paper"
          open={editing}
          onClose={() => setEditing(false)}
        >
          <DialogTitle>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => setEditing(false)}
                  color="primary"
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <BookingVitalsDialog
              templateList={vitalTemplates}
              vitalList={vitalList}
              editingVital={editVital}
              updateVitalTemplate={updateVitalTemplate}
              updateVitalField={updateVitalField}
              updateVitalValue={updateVitalValue}
              confirmVital={confirmVitalChange}
              cancelVital={cancelVitalChange}
              roomColor={room.color}
            />
          </DialogTitle>
        </Dialog>
      }
    </>
  )
}

export default connect(state => ({
  user: state.user,
}))(BookingForm)