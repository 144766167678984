import axios from 'axios'

import store from '../../store'
import actions from '../../store/actions'
import location from '../location'

let instance

const getAxios = (config) => {
  if (instance) return instance(config)

  instance = axios.create({
    baseURL: location.backendURL(),
    timeout: 3000,
    withCredentials: true,
  })

  instance.interceptors.response.use(response => response, error => {
    switch (error.response.status) {
      case 401:
        store.dispatch(actions.logout())
        break
      default:
    }

    return Promise.reject(error)
  })

  return instance(config)
}

export default getAxios
