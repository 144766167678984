import getAxios from './axios'

const list = (bookingId) => getAxios({
    url: `/bookings/${bookingId}/vitals`,
    method: 'GET',
})

const create = (bookingId, userId, userName, templateId, vital, value1, value2, value3, value4) => getAxios({
    url: `/bookings/${bookingId}/vitals`,
    method: 'PUT',
    data: {
        userId,
        userName,
        templateId,
        vital,
        value1,
        value2,
        value3,
        value4,
    },
})

const update = (bookingId, vitalId, userId, userName, templateId, vital, value1, value2, value3, value4) => getAxios({
    url: `/bookings/${bookingId}/vitals/${vitalId}`,
    method: 'PUT',
    data: {
        userId,
        userName,
        templateId,
        vital,
        value1,
        value2,
        value3,
        value4,
    },
})

const remove = (bookingId, vitalId) => getAxios({
    url: `/bookings/${bookingId}/vitals/${vitalId}`,
    method: 'DELETE',
})

const bookingVitals = {
    list, 
    create,
    update,
    delete: remove,
}

export default bookingVitals