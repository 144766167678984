import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import './App.css'
import {
  Box,
  CssBaseline,
  makeStyles,
} from '@material-ui/core'

import AdminRoute from './components/AdminRoute'
import AdminLogin from './pages/admin/AdminLogin'
import AdminHome from './pages/admin/AdminHome'
import AdminRoom from './pages/admin/AdminRoom'
import AdminSummary from './pages/admin/AdminSummary'
import AdminUserManagement from './pages/admin/AdminUserManagement'
import AdminClinicManagement from './pages/admin/AdminClinicManagement'
import AdminSettingManagement from './pages/admin/AdminSettingManagement'
import AdminGroupEducation from './pages/admin/AdminGroupEducation'

import Home from './pages/Home'
import Room from './pages/Room'
import Done from './pages/Done'
import Booking from './pages/Booking'
import VitalSignEnter from './pages/VitalSignEnter'

import TopBar from './components/TopBar'
import SideMenu from './components/SideMenu'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
}))

export default function App() {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <TopBar onDrawerToggle={() => setOpenDrawer(!openDrawer)} />
        <main>
          <Box p={3}>
            <div>
              <Switch>
                {/* Admin routers */}
                <Route path="/admin/login">
                  <AdminLogin />
                </Route>
                <AdminRoute path="/admin/room">
                  <AdminRoom />
                </AdminRoute>
                <AdminRoute path="/admin/user_management">
                  <AdminUserManagement />
                </AdminRoute>
                <AdminRoute path="/admin/clinic_management">
                  <AdminClinicManagement />
                </AdminRoute>
                <AdminRoute path="/admin/summary_report">
                  <AdminSummary />
                </AdminRoute>
                <AdminRoute path="/admin/group_education">
                  <AdminGroupEducation />
                </AdminRoute>
                <AdminRoute path="/admin/setting_management">
                  <AdminSettingManagement />
                </AdminRoute>
                <AdminRoute exact path="/admin">
                  <AdminHome />
                </AdminRoute>
                <AdminRoute path="/admin/*">
                  <Redirect to="/admin"></Redirect>
                </AdminRoute>

                {/* Patient routes */}
                <Route path="/room">
                  <Room />
                </Route>
                <Route path="/done">
                  <Done />
                </Route>
                <Route path="/data">
                  <VitalSignEnter />
                </Route>
                <Route path="/booking">
                  <Booking />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="*">
                  <Redirect to="/"></Redirect>
                </Route>
              </Switch>
            </div>
          </Box>
        </main>
        <SideMenu
          open={openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
        ></SideMenu>
      </Router>
    </div>
  )
}
