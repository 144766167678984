import axios from 'axios'
import location from '../location'

const observationCharts = {
  total: (hn) => {
    return axios.get({
      baseURL: location.backendURL(),
      url: 'api/GetPatientTotalObsByHN',
      params: {
        hn,
      },
    })
  },

  embed: (hn, page) => {
    const url = new URL('/PrintChart', location.backendURL())
    url.searchParams.set('hn', hn)
    url.searchParams.set('page', page)

    return url.href
  },

  /**
   *
   * @param {string} hn
   * @param {string} name
   * @param {string} sys
   * @param {string} dia
   * @param {string} map
   * @param {string} temp
   * @param {string} spo2
   * @param {string} weight
   * @param {string} imt
   * @param {string} cigarette
   * @param {string} aerobic
   * @param {string} dateTime
   * @returns
   */
  // should be deprecated
  // upload: (hn, name, sys, dia, map, temp, spo2, dateTime) => {
  //   const url = process.env.REACT_APP_CHART_API_HOST + '/api/UploadBPMonitorData'
  //   return axios.post(url, {
  //     hn: hn,
  //     DeviceId: 'virtual-clinics',
  //     name,
  //     MeasureDateTime: dateTime,
  //     FormData:  [
  //       {
  //         type_id: 1,
  //         value: sys,
  //       }, {
  //         type_id: 2,
  //         value: map,
  //       }, {
  //         type_id: 3,
  //         value: dia,
  //       }, {
  //         type_id: 5,
  //         value: temp,
  //       }, {
  //         type_id: 6,
  //         value: spo2,
  //       },
  //     ],
  //   })
  // },
}
export default observationCharts