import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ({color}) => color,
    color: ({textColor}) => textColor,
    '&:hover': {
      backgroundColor: ({color}) => color,
    },
    '&:focus': {
      backgroundColor: ({color}) => color,
    },
  },

  label: {
    color: ({textColor}) => textColor,
    textTransform: 'capitalize',
  },
}))

export default function ColoredButton(props) {
  const {
    color,
    textColor,
    children,
  } = props
  const classes = useStyles({color, textColor})
  const otherProps = Object.assign({}, props)
  delete otherProps.color
  delete otherProps.textColor
  delete otherProps.children

  return (
    <Button
      classes={{ label: classes.label }}
      className={classes.root}
      { ...otherProps }
    >{children}</Button>
  )
}