import React, { useState} from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  Typography,
} from '@material-ui/core'

import api from '../../../api'

import ColoredButton from '../../ColoredButton'
import { connect } from 'react-redux'

const useStyles = makeStyles({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    select: ({ color }) => ({
      borderColor: color,
      '&:focus': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& label.Mui-focused': {
        color: color,
      },
    }),
  })

  const pdfFileExtension = 'application/pdf'

  function LeafletForm(props) {
    const { onLeafletCreated, onCancelClick } = props
    const classes = useStyles({ color: 'blue' })
    const { handleSubmit, errors, control } = useForm({
        reValidateMode: 'onSubmit',
    })
    const [selectedFile, setFile] = useState(null)
    const [uploadingLeaflet, setUploadingLeaflet] = useState(false)
    
    async function onSubmitHandler(data) {
        setUploadingLeaflet(true)

        if (!data.label) {
            setUploadingLeaflet(false)
            alert('Label is empty. please check.')
            return
        }

        if (!selectedFile) {
            setUploadingLeaflet(false)
            alert('File is not selected, please check.')
            return
        }

        try {
            const leaflet = await api.routes.leaflets.upload(
                data.label,
                selectedFile,
            )

            console.log(leaflet)

            onLeafletCreated && onLeafletCreated(leaflet.data)
        } catch (err) {
            alert('Error occurs when uploading file')
        } finally {
            setUploadingLeaflet(false)
        }
    }

    const handleFileSelected = (event) => {
        const file = event.target.files[0]
        if (file.type) {
            if (file.type === pdfFileExtension) {
                setFile(file)
            }
            else {
                setFile(null)
                alert('Please select a pdf file')
            }
        }
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller 
                            control={control}
                            rules={{ required: true }}
                            name="label"
                            defaultValue={''}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Label"
                                    className={classes.textField}
                                    error={!!errors.label}
                                    disabled={uploadingLeaflet}
                                ></TextField>
                            }
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Button
                            color="primary"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            component="label"
                            disabled={uploadingLeaflet}
                        >
                            Upload PDF
                            <input
                                type="file"
                                onClick={(event) => event.target.files = null}
                                onChange={handleFileSelected}
                                hidden
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        { selectedFile !== null ? (
                            <Typography variant="h5" component="h5">{ selectedFile.name }</Typography>
                        ): (
                            <Typography variant="h5" component="h5">Please select a file</Typography>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Button
                                    color="secondary"
                                    margin="normal"
                                    fullWidth
                                    variant="contained"
                                    disabled={uploadingLeaflet}
                                    onClick={onCancelClick}
                                >Cancel</Button>
                            </Grid>
                            <Grid item xs>
                                <ColoredButton
                                    color={'blue'}
                                    textColor="white"
                                    margin="normal"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={uploadingLeaflet}
                                >Create</ColoredButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
  }

  export default connect(state => ({
      user: state.user,
  })) (LeafletForm)