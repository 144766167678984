import React, { useState, useEffect, useRef } from 'react'
import {
    Box,
    Card,
    Button,
    Dialog,
    Grid,
    IconButton,
    Paper,
    Slide,
  } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'

import LeafletTable from '../Leaflets/LeafletTable'
import LeafletForm from '../Leaflets/LeafletForm'
import LeafletDisplay from '../../LeafletDisplay'

import routes from '../../../api/routes'
import location from '../../../api/location'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function LeafletList() {
    const [leaflets, setLeaflets] = useState([])
    const [creatingLeaflet, setCreatingLeaflet] = useState(false)
    const [viewingLeaflet, setViewingLeaflet] = useState('')

    const refresh = async () => {
        const { data } = await routes.leaflets.list()
        setLeaflets(data)
    }

    const viewLeafletHandler = async (leaflet) => {
        setViewingLeaflet(`${location.backendURL()}/viewleaflets/${leaflet.id}`)
    }

    const deleteLeafletHandler = async (leaflet) => {
        if (window.confirm('Confirm to remove leaflet?')) {
            await routes.leaflets.delete(leaflet.id)
            refresh()
        }
    }

    const onLeafletCreateHandler = () => {
        setCreatingLeaflet(false)
        refresh()
    }

    useEffect(() => {
        refresh()
    }, [])

    return (
    <Box mt={3}>
        <Paper variant="outlined">
            <Box p={3}>
                <Grid container spacing={3} alignContent="center">
                    <Grid item xs>
                        <Button 
                            color="inherit"
                            startIcon={<AddIcon />}
                            onClick={() => setCreatingLeaflet(true)}
                        >Add Leaflet</Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper>

        <Grid container spacing={3}>
            <Grid item xs>
                <Card variant="outlined">
                    <LeafletTable
                        leaflets={leaflets}
                        onViewClick={viewLeafletHandler}
                        onDeleteClick={deleteLeafletHandler}
                    />
                </Card>
            </Grid>
        </Grid>

        <Dialog
            fullWidth
            maxWidth="lg"
            open={!!creatingLeaflet}
            onClose={() => setCreatingLeaflet(false)}
        >
            { creatingLeaflet && 
                <>
                    <Box p={3}>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton
                                size="small"
                                onClick={() => setCreatingLeaflet(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <LeafletForm
                            onCancelClick={() => setCreatingLeaflet(false)}
                            onLeafletCreated={onLeafletCreateHandler}
                        />
                    </Box>
                </>
            }
        </Dialog>

        <Dialog
            fullWidth
            maxWidth="lg"
            TransitionComponent={Transition}
            open={viewingLeaflet !== ''}
            onClose={() => setViewingLeaflet('')}
        >
            { viewingLeaflet !== '' && 
                <>
                    <Box p={3}>
                        <Box display="flex" justifyContent="flex-start">
                            <IconButton
                                size="small"
                                onClick={() => setViewingLeaflet('')}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <LeafletDisplay
                            path={viewingLeaflet}
                        />
                    </Box>
                </>
            }
        </Dialog>
    </Box>
    )
}

export default LeafletList