import getAxios from './axios'

/**
 * Get the booking details
 * @param {string} code
 */
const booking = (code) => getAxios({
  url: `/patients/${code}/booking/`,
  method: 'GET',
})

/**
 * Get room list
 * @param {string} code
 */
const room = (code) => getAxios({
  url: `/patients/${code}/room`,
  method: 'GET',
})

const validate = (code) => getAxios({
  url: `/patients/${code}/validate`,
  method: 'POST',
})

/**
 * Check the patient booking
 * @param {string} code
 * @param {string} dob
 * @param {string} hkid
 */

const login = (code, dob, hkid) => {
  const data = {
    dob,
    hkid,
  }

  if (code && code.length > 6 && code[0] === 'T') {
    data.t = 1
  }

  return getAxios({
    url: `/patients/${code}/login`,
    method: 'POST',
    data,
  })
}

/**
 * Get material groups
 * @param {string} code
 * @param {string} roomId
 */
const materialGroups = (code, roomId) => getAxios({
  url: `/patients/${code}/room/${roomId}/material_groups`,
  method: 'GET',
})

/**
 * Get material group's materials
 * @param {string} code
 * @param {string} materialGroupId
 */
const materials = (code, materialGroupId) => getAxios({
  url: `/patients/${code}/material_groups/${materialGroupId}/materials`,
  method: 'GET',
})

const bookingVitals = (code) => getAxios({
  url: `/patients/${code}/booking_vitals`,
  method: 'GET',
})

/**
  *
  * @param {string} code
  * @param {string} sys
  * @param {string} dia
  * @param {string} map
  * @param {string} temp
  * @param {string} spo2
  * @param {string} weight
  * @param {string} imt
  * @param {string} cigarette
  * @param {string} aerobic
  * @param {string} pulse
  * @param {string} dateTime
 */
const uploadObservation = (code, data) => {
  return getAxios({
    url: `/patients/${code}/observations`,
    method: 'POST',
    data,
  })
}

const checkBooking = (hkid, year) => {
  const params = {
    hkid,
    year,
  }

  return getAxios({
    url: '/patientsbooking',
    method: 'GET',
    params,
  })
}

const patients = {
  booking,
  room,
  validate,
  login,
  materialGroups,
  materials,
  uploadObservation,
  bookingVitals,
  checkBooking,
}

export default patients