import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core'

import api from '../../../api'
import utils from '../../../utils'

import ColoredButton from '../../ColoredButton'
import { connect } from 'react-redux'

const useStyles = makeStyles({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    select: ({ color }) => ({
      borderColor: color,
      '&:focus': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& label.Mui-focused': {
        color: color,
      },
    }),
    'contentField > .the-trick': {
        // Retains width/height of original word
        display: 'inline-block',
        position: 'relative',
        padding: '4px 16px',
        right: '9999px',
      
        '&:after': {
          content: '\\1f680',
          position: 'absolute',
          top: '2px',
          right: '-9999px',
          width: '100%',
          textAlign: 'center',
          height: '100%',
          color: '#fff',
          fontSize: '50px',
          lineHeight: '30.8px',
          fontFamily: 'entypo, sans-serif',
        },
      },
  })

  function TemplateForm(props) {
    const { onTemplateCreated, onTemplateUpdated, onCancelClick, user } = props
    const classes = useStyles({ color: 'orange' })
    const { handleSubmit, errors, control, setValue, getValues, watch } = useForm({
        reValidateMode: 'onSubmit',
    })
    const [template, setTemplate] = useState(props.vitalTemplate || {
        label: '',
        englishContent: '',
        chineseContent: '',
    })
    const chineseContentWatch = watch('chineseContent')
    const englishContentWatch = watch('englishContent')
    const chinesePreviousContent = useRef(false)
    const englishPreviousContent = useRef(false)

    const [updatingTemplate, setUpdatingTemplate] = useState(false)
    const isUpdate = !!props.vitalTemplate

    function addChineseInputField(index) {
      const inputFieldValue = utils.getInputFieldConstant(index)

      const chineseContent = getValues('chineseContent')
      const modifiedChinese = chineseContent ? chineseContent + inputFieldValue : inputFieldValue
      setValue('chineseContent', modifiedChinese)
    }

    function addEnglishInputField(index) {
      const inputFieldValue = utils.getInputFieldConstant(index)

      const englishContent = getValues('englishContent')
      const modifiedEnglish = englishContent ? englishContent + inputFieldValue : inputFieldValue
      setValue('englishContent', modifiedEnglish)
    }

    useEffect(() => {
      // if (chinesePreviousContent.current && chineseContentWatch) {
      //   const inputFieldValue = utils.getInputFieldRegez()
      //   const inputFieldRegez = new RegExp(inputFieldValue, 'g')
  
      //   const previousNum = (previousInputField || []).length
      //   const currentNum = (chineseContentWatch.match(inputFieldRegez) || []).length
      // }

      chinesePreviousContent.current = chineseContentWatch
    }, [chineseContentWatch])

    useEffect(() => {
      // console.log('%c' + englishPreviousContent.current, 'color: red; background: cyan;')
      // console.log(englishContentWatch)
      englishPreviousContent.current = englishContentWatch
    }, [englishContentWatch])

    async function onSubmitHandler(data) {
        setUpdatingTemplate(true)

        if (!data.label) {
            setUpdatingTemplate(false)
            alert('Label is empty, please check.')
            return
        }

        if (!data.chineseContent) {
          setUpdatingTemplate(false)
          alert('Chinese Content is empty, please check.')
          return
        }

        if (!data.englishContent) {
          setUpdatingTemplate(false)
          alert('English Content is empty, please check.')
          return
        }

        try {
          if (isUpdate) {
            const template = await api.routes.vitalTemplates.update(
              props.vitalTemplate.id,
              user.id,
              user.name,
              data.label,
              data.chineseContent,
              data.englishContent,
            )

            onTemplateUpdated && onTemplateUpdated(template.data)
          } else {
            const template = await api.routes.vitalTemplates.create(
              user.id,
              user.name,
              data.label,
              data.chineseContent,
              data.englishContent,
            )

            onTemplateCreated && onTemplateCreated(template.data)
          }
        } catch (err) {
            console.log(err)
            alert('Error occurs when saving the template')
        } finally {
            setUpdatingTemplate(false)
        }
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="label"
                            defaultValue={template.label}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Label"
                                    className={classes.textField}
                                    error={!!errors.label}
                                    disabled={updatingTemplate}
                                ></TextField>
                            }
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addChineseInputField(1)}}
                          >Add Input1</Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addChineseInputField(2)}}
                          >Add Input2</Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addChineseInputField(3)}}
                          >Add Input3</Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addChineseInputField(4)}}
                          >Add Input4</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="chineseContent"
                            defaultValue={template.chineseContent}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    fullWidth
                                    label="Chinese Content"
                                    className={classes.textField}
                                    error={!!errors.chineseContent}
                                    disabled={updatingTemplate}
                                ></TextField>
                            }
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addEnglishInputField(1)}}
                          >Add Input1</Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addEnglishInputField(2)}}
                          >Add Input2</Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addEnglishInputField(3)}}
                          >Add Input3</Button>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            color="orange"
                            margin="normal"
                            fullWidth
                            variant="contained"
                            onClick={() => {addEnglishInputField(4)}}
                          >Add Input4</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="englishContent"
                            defaultValue={template.englishContent}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    fullWidth
                                    label="English Content"
                                    className={classes.textField}
                                    error={!!errors.englishContent}
                                    disabled={updatingTemplate}
                                ></TextField>
                            }
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Button
                                    color="secondary"
                                    margin="normal"
                                    fullWidth
                                    variant="contained"
                                    onClick={onCancelClick}
                                >Cancel</Button>
                            </Grid>
                            <Grid item xs>
                                <ColoredButton
                                    color={'orange'}
                                    textColor="white"
                                    margin="normal"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={updatingTemplate}
                                >{!!props.vitalTemplate ? 'Update' : 'Create'}</ColoredButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
  }

  export default connect(state => ({
    user: state.user,
  }))(TemplateForm)