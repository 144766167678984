import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import {
    Grid,
    Button,
    Box,
    Slide,
    Card,
    AppBar,
    Toolbar,
    Dialog,
} from '@material-ui/core'
import DateDayjsUtils from '@date-io/dayjs'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

import GroupForm from '../../components/GroupEducation/GroupForm'
import DialogContent from '../../components/DialogContent'

import routes from '../../api/routes'
import utils from '../../utils'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

const dateFormat = 'YYYY-MM-DD'

function AdminGroupEducation(props) {
    const { room } = props
    const [editRecord, setEditRecord] = useState(false)

    const [todayDate, setDate] = useState(dayjs())
    const [selectedDate, setSelectedDate] = useState(dayjs())

    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [errorMessage, setErrorMessage] = useState('')

    const [dataList, setDataList] = useState([])
    const [fetched, setFetched] = useState(false)
    const [count, setCount] = useState(0)

    const [selectedRecord, setSeletedRecord] = useState(null)

    const [attendancespeciality, setAttendanceSpeciality] = useState([])
    const [periOpReason, setPeriOpReason] = useState([])
    const [physioReason, setPhysioReason] = useState([])

    const groupEducationLabel = useMemo(() => {
        if (!room) return ''
        if (room.key === utils.constants.room_identifier.surgery_school) return 'Group Education'
        if (room.key === utils.constants.room_identifier.npac) return 'Number of Patient Attendance'
        return ''
    }, [room])

    const shouldShowRelative = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return false
        return false
      }, [room])

    const shouldShowGroupEducation = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return true
        return false
    }, [room])

    const shouldShowReasonConsultation = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return false
        return false
    }, [room])

    const shouldShowOther = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return false
        return false
    }, [room])
    
    const onCreateClick = () => {
        setSelectedDate(dayjs())
        setSeletedRecord(null)
        setEditRecord(true)
    }
    
    const setDateToSelected = (date) => {
        setSelectedDate(date)
        fetchSelected(date)
    }

    const removeData = async (date) => {
        const valid = window.confirm(`Are you sure you want to remove record from ${date}?`)
        if (valid) {
            const response = await routes.groupEducations.remove(date, room.key)
            if (response.data) {
                console.log(response.data)
                fetchRange(startDate, endDate)
            }
        }
    }

    const onStartDateChange = (date) => {
        setStartDate(date)
    }

    const onEndDateChange = (date) => {
        setEndDate(date)
    }

    const onSearchClick = () => {
        setErrorMessage('')

        if (startDate.diff(endDate, 'days') > 0) {
            setErrorMessage('Start date must be before end date')
            return
        }

        fetchRange(startDate, endDate)
    }

    const parseDataToForm = (data) => {
        let output = {}
        if (data) {
            const jsonList = JSON.parse(data.value)

            let groupEducatoinData = []
            attendancespeciality.forEach(speciality => {
                const specialityData = jsonList.filter(s => s.reason_id === speciality.id)
                if (specialityData.length !== 0) {
                    let special = {
                        specialty: parseInt(speciality.id),
                    }
                    for (let i = 0; i < specialityData.length; i++) { 
                        special[specialityData[i].name] = specialityData[i].number
                    }
                    groupEducatoinData.push(special)
                }
            })

            output.attendances = groupEducatoinData

            periOpReason.forEach(reason => {
                const reasonData = jsonList.find(s => s.reason_id === reason.id)
                if (reasonData) {
                    output[`reason${reason.id}`] = reasonData.number
                } else {
                    output[`reason${reason.id}`] = 0
                }
            })

            physioReason.forEach(reason => {
                const reasonData = jsonList.find(s => s.reason_id === reason.id)
                if (reasonData) {
                    output[`reason${reason.id}`] = reasonData.number
                } else {
                    output[`reason${reason.id}`] = 0
                }
            })

            const npacData = jsonList.find(s => s.name === 'onlineNPAC')
            if (npacData) {
                output['onlineNPAC'] = npacData.number
            } else {
                output['onlineNPAC'] = 0
            }
        } else {
            return null
        }
        return output
    }

    const parseRangeDataToForm = (inputData, startDate, endDate) => {
        let output = []

        inputData.sort((a, b) => {
            return dayjs(b.record_date).diff(dayjs(a.record_date))
        })
        inputData.forEach((dataByDate) => {
            const jsonList = JSON.parse(dataByDate.value)
            const groupEducationList = jsonList.filter(s => s.reason_id && s.name)
            const nurseList = jsonList.filter(s => !s.reason_id || !s.name)

            let partOneOutput = {}  // with name and id
            let partTwoOutput = {}  // with only id
            let partThreeOutput = {}    // with only name
            
            let patientTotal = 0
            let relativeTotal = 0
            groupEducationList.forEach((item) => {
                if (item.name === 'patient') {
                    patientTotal += item.number
                } else if (item.name === 'relative') {
                    relativeTotal += item.number
                }
                partOneOutput[`${item.name}-${item.reason_id}`] = item.number
            })

            nurseList.forEach((item) => {
                if (item.name) {
                    partThreeOutput[item.name] = item.number
                } else {
                    partTwoOutput[item.reason_id] = item.number
                }
            })

            output.push({
                date: dayjs(dataByDate.record_date).format('YYYY-MM-DD'),
                data: {
                    patientTotal,
                    relativeTotal,
                    partOneOutput,
                    partTwoOutput,
                    partThreeOutput,
                },
            })
        })

        return output
    }

    const init = async () => {
        const { data } = await routes.consultationReasons.list()
        const specialityList = data.filter(s => s.reason_type === 'Speciality')
        const periOpList = data.filter(s => s.reason_type === 'PeriOp')
        const physioList = data.filter(s => s.reason_type === 'Physio')
        const sortedPeriOp = periOpList.sort((a, b) => a.order_number - b.order_number)
        const sortedPhysio = physioList.sort((a, b) => a.order_number - b.order_number)
        setPeriOpReason(sortedPeriOp)
        setPhysioReason(sortedPhysio)
        setAttendanceSpeciality(specialityList)

        setErrorMessage('')
    }

    const fetchSelected = async (date) => {
        const response = await routes.groupEducations.get(date, room.key)
        if (response.data) {
            if (!response.data.exist) {
                const data = parseDataToForm(null)
                setSeletedRecord(data)
            } else {
                const data = parseDataToForm(response.data.attendance)
                setSeletedRecord(data)
            }
            setEditRecord(true)
        } else {
            setSeletedRecord(null)
        }
    }

    const fetchRange = async (start, end) => {
        const response = await routes.groupEducations.list(start.format(dateFormat), end.format(dateFormat), room.key)
    
        if (response.data) {
            setFetched(true)
            if (response.data.length === 0) {
                const data = parseRangeDataToForm([], start, end)
                setDataList(data)
                setCount(0)
            } else {
                const data = parseRangeDataToForm(response.data, start, end)
                setDataList(data)
                setCount(data.length)
            }
        } else {
            const data = parseRangeDataToForm([], start, end)
            setDataList(data)
        }
    }

    const onSelectedCreatedHandler = async () => {
        fetchRange(startDate, endDate)
        closeRecordDialog()
        setEditRecord(false)
    }

    const closeRecordDialog = () => {
        setEditRecord(false)
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <Box mt={1}>
                <Grid container spacing={3} justify="center" alignItems="flex-start">
                    <Grid item xs={12}>
                        <AppBar position="static" style={{ backgroundColor: room.color }}>
                            <Toolbar variant="dense">
                                <Box display="flex" flexGrow={1}>
                                    Today date: ({todayDate.format(dateFormat)})
                                </Box>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <Box p={2}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <div style={{
                                            padding: '5px',
                                        }}>
                                            <MuiPickersUtilsProvider utils={DateDayjsUtils}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    margin="normal"
                                                    label="Start Date"
                                                    format="YYYY-MM-DD"
                                                    value={startDate}
                                                    onChange={onStartDateChange}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div style={{
                                            padding: '5px',
                                        }}>
                                            <MuiPickersUtilsProvider utils={DateDayjsUtils}>
                                                <KeyboardDatePicker
                                                    fullWidth
                                                    margin="normal"
                                                    label="End Date"
                                                    format="YYYY-MM-DD"
                                                    value={endDate}
                                                    onChange={onEndDateChange}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div style={{
                                            padding: '5px',
                                            marginTop: '25px',
                                        }}>
                                            <Button style={{
                                                backgroundColor: room.color,
                                                color: 'white',
                                            }}
                                            variant="contained"
                                            onClick={onSearchClick}
                                            >Search</Button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div style={{
                                            padding: '5px',
                                            marginTop: '25px',
                                        }}>
                                            <Button style={{
                                                backgroundColor: room.color,
                                                color: 'white',
                                            }}
                                            variant="contained"
                                            onClick={onCreateClick}
                                            >Create</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                        {errorMessage && (
                            <Card variant="outlined">
                                <Box p={2}>
                                    {errorMessage}
                                </Box>
                            </Card>
                        )}
                        {dataList.length === 0 && (
                            <Card variant="outlined">
                                <Box p={2}>
                                    Please select a range of date to search
                                </Box>
                            </Card>
                        )}
                        {fetched && (
                            <Card variant="outlined">
                                <Box p={2}>
                                    Total number of sections: {count}
                                </Box>
                            </Card>
                        )}
                        {dataList.map((dateRecord, index) => (
                            <Card variant="outlined" key={`date${index}`}>
                                <Box p={4}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3} style={{ paddingTop: '12px' }}>
                                            Date: {dateRecord.date}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button style={{
                                                    backgroundColor: room.color,
                                                    color: 'white',
                                                }}
                                                variant="contained"
                                                onClick={() => setDateToSelected(dateRecord.date)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    marginLeft: '5px',
                                                }}
                                                variant="contained"
                                                onClick={() => removeData(dateRecord.date)}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                        {dateRecord.data ? (
                                            <>
                                                <Grid item xs={12}>
                                                    {shouldShowGroupEducation && (
                                                        <Card variant="outlined" style={{
                                                            padding: '15px',
                                                        }}>
                                                            {groupEducationLabel}:
                                                            {attendancespeciality.map((speciality, index2) => {
                                                                const output = dateRecord.data.partOneOutput
                                                                const patientValue = output['patient-' + speciality.id]
                                                                const relativeValue = output['relative-' + speciality.id]
                                                                // since it can be zero, check if it is undefined
                                                                if (patientValue !== undefined || relativeValue !== undefined) {
                                                                    return (
                                                                        <Grid item xs={12} key={index + 'speciality' + index2}>
                                                                            <Grid container spacing={0}>
                                                                                {shouldShowRelative ? (
                                                                                    <>
                                                                                        <Grid item xs={1}>
                                                                                            {speciality.content}
                                                                                        </Grid>
                                                                                        <Grid item xs={2}>
                                                                                            Patient:
                                                                                        </Grid>
                                                                                        <Grid item xs={2}>
                                                                                            {patientValue}
                                                                                        </Grid>
                                                                                        <Grid item xs={2}>
                                                                                            Relative:
                                                                                        </Grid>
                                                                                        <Grid item xs={2}>
                                                                                            {relativeValue}
                                                                                        </Grid>
                                                                                        <Grid item xs={2}>
                                                                                            Total:
                                                                                        </Grid>
                                                                                        <Grid item xs={1}>
                                                                                            {patientValue + relativeValue}
                                                                                        </Grid>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Grid item xs={4}>
                                                                                            {speciality.content}
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            Patient:
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            {patientValue}
                                                                                        </Grid>
                                                                                    </>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                                }
                                                                return (
                                                                    <div key={index + 'speciality' + index2}></div>
                                                                )
                                                            })}
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={0} style={{
                                                                    fontWeight: 'bold',
                                                                }}>
                                                                    {shouldShowRelative ? (
                                                                        <>
                                                                            <Grid item xs={1}>
                                                                                Total:
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                Patient:
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                {dateRecord.data.patientTotal}
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                Relative:
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                {dateRecord.data.relativeTotal}
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                Total:
                                                                            </Grid>
                                                                            <Grid item xs={1}>
                                                                                {dateRecord.data.patientTotal + dateRecord.data.relativeTotal}
                                                                            </Grid>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Grid item xs={4}>
                                                                                Total:
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                Patient:
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                {dateRecord.data.patientTotal}
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    )}
                                                </Grid>
                                                {shouldShowReasonConsultation && (
                                                    <>
                                                        <Grid item xs={6}>
                                                            <Card variant="outlined">
                                                                <Grid container spacing={1} style={{ margin: '10px' }}>
                                                                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                                                                        Consultation By PeriOp Nurse :
                                                                    </Grid>
                                                                    {periOpReason.map((item, index) => (
                                                                        <>
                                                                            <Grid item xs={6} key={'periop' + index}>
                                                                                {item.content}: 
                                                                            </Grid>
                                                                            <Grid item xs={6} key={'periopnum' + index}>
                                                                                {dateRecord.data.partTwoOutput[item.id] ?? 0}
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Card variant="outlined">
                                                                <Grid container spacing={1} style={{ margin: '10px' }}>
                                                                    <Grid item xs={12} style={{ fontWeight: 'bold' }}>
                                                                        Consultation By Physiotherapist :
                                                                    </Grid>
                                                                    {physioReason.map((item, index) => (
                                                                        <>
                                                                            <Grid item xs={6} key={'periop' + index}>
                                                                                {item.content}: 
                                                                            </Grid>
                                                                            <Grid item xs={6} key={'periopnum' + index}>
                                                                                {dateRecord.data.partTwoOutput[item.id] ?? 0}
                                                                            </Grid>
                                                                        </>
                                                                    ))}
    
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                    </>
                                                )}
                                                {shouldShowOther && (
                                                    <>
                                                        <Grid item xs={6}>
                                                            No of online NPAC FU: 
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {dateRecord.data.partThreeOutput.onlineNPAC}
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Grid item xs={12}>
                                                No data found at {dateRecord.date}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Card>
                        ))}
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                fullScreen
                open={editRecord}
                onClose={() => setEditRecord(false)}
                TransitionComponent={Transition}
            >
                <DialogContent
                    title={'Edit Record'}
                    color={room.color}
                    onClose={() => setEditRecord(false)}
                >
                    <GroupForm 
                        onSelectedCreatedHandler={onSelectedCreatedHandler}
                        onCancelClick={() => setEditRecord(false)}
                        attendancespeciality={attendancespeciality}
                        periOpReason={periOpReason}
                        physioReason={physioReason}
                        groupData={selectedRecord}
                        date={selectedDate}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}


export default connect(state => ({
    room: state.room,
  }))(AdminGroupEducation)