// import dayjs from 'dayjs'
import getAxios from './axios'
import constants from '../../libs/constants'

// from YYYY-MM-DD
// to  YYYY-MM-DD
const list = (roomId, code, from, to, status, page, sopd) => {
  const params = {
    'room_id': roomId,
  }

  params.from = from ? from : ''
  params.to = to ? to : ''

  if (code) {
    params.code = code
  }

  params.status = status
  params.page = page
  params.size = constants.pageSize
  params.sopd = sopd

  return getAxios({
    url: '/bookings',
    method: 'GET',
    params,
  })
}

const create = (roomId, sopd, hkid, name, chinese, gender,
  dob, time, otDate, diagnosis,
  operation, referFrom, phone, conversationType) =>
{
  const data = {
    roomId, sopd, hkid, name, chinese, gender,
    dob, time, otDate, diagnosis,
    operation, referFrom, phone, conversationType,
  }

  return getAxios({
    url: '/bookings',
    method: 'POST',
    data,
  })
}

const show = (id) => getAxios({
  url: `/bookings/${id}`,
  method: 'GET',
})

const update = (id, data = {}) => {
  return getAxios({
    url: `/bookings/${id}`,
    method: 'PATCH',
    data: data,
  })
}

const destroy = (id) => getAxios({
  url: `/bookings/${id}`,
  method: 'DELETE',
})

const bookings = {
  list,
  create,
  show,
  update,
  destroy,
}

export default bookings