import React from 'react'
import dayjs from 'dayjs'
import {
  Box,
  Grid,
  Typography,
  ListItem,
  Card,
  Button,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import TextItem from './TextItem'
import YoutubeElement from './YoutubeElement'
import ImageElement from './ImageElement'

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
}))

export default function MaterialItem(props) {
  const {
    loading,
    item,
    onDelete,
    onMoveUp,
    onMoveDown,
  } = props

  const classes = useStyles()

  return (
    <ListItem>
      <Box m={1} flexGrow={1}>
        <Card variant="outlined">
          <Box p={1}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <Box display="flex" height="100%" alignItems="center" pr={2}>
                  {(item.type === 'text') && <TextItem content={item.content} />}
                  {(item.type === 'youtube_link') && <YoutubeElement content={item.content} />}
                  {(item.type === 'image') && <ImageElement content={item.content} />}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
                  <Grid item>
                    <Typography variant="h5">Title: {item.title}</Typography>
                    <Typography variant="subtitle1">Content Type: {item.type.toUpperCase().replace('_', ' ')}</Typography>
                    <Typography variant="subtitle1">Creator: {item.creator.name}</Typography>
                    <Typography variant="subtitle1">Created At: {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}</Typography>
                  </Grid>
                  <Grid item className={classes.root}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      disableElevation
                      disabled={loading}
                      startIcon={<DeleteIcon />}
                      onClick={() => onDelete(item.id)}
                    >Delete</Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      disableElevation
                      disabled={loading}
                      startIcon={<ArrowUpwardIcon />}
                      onClick={() => onMoveUp(item.id)}
                    >Move Up</Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      disableElevation
                      disabled={loading}
                      startIcon={<ArrowDownwardIcon />}
                      onClick={() => onMoveDown(item.id)}
                    >Move Up</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </ListItem>
  )
}