import React, { useRef, useEffect } from 'react'
import {
  Box,
  makeStyles,
} from '@material-ui/core'

import MessengerDialog from './MessengerDialog'

const useStyles = makeStyles(theme => ({
    messageBody: {
      overflow: 'scroll',
    },
  }),
)

export default function MessengerBody(props) {
  const {
    sender,
    messages,
    height,
    color,
  } = props

  const classes = useStyles()
  const boxRef = useRef(null)

  useEffect(() => {
    boxRef.current.scrollTop = boxRef.current.scrollHeight
  })

  return (
    <Box
      ref={boxRef}
      height={height}
      className={classes.messageBody}
      p={1}
    >
      { messages && messages.map((m, i) => {
        return (
          <MessengerDialog
            key={i+m.msg}
            isSender={m.code === sender}
            isPatient={m.code?.length === 6}
            color={color}
            message={m.msg}
          />
        )
      })}
    </Box>
  )
}