import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  Box,
  Card,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'

import ReasonTable from '../ConsultationReasons/ReasonTable'
import ReasonForm from '../ConsultationReasons/ReasonForm'

import routes from '../../../api/routes'

const reasonList = {
    PeriOp: 0,
    Physio: 1,
    Speciality: 2,
}

function ConsultationReasonList() {
    const [reasons, setReasons] = useState([])
    const [creatingReason, setCreatingReason] = useState(false)
    const [editingReason, setEditingReason] = useState(null)
    const shouldRefresh = useRef(true)

    const sortedReasons = useMemo(() => {
        return reasons.sort((a, b) => {
            if (a.reason_type === b.reason_type) {
                return a.order_number - b.order_number
            } else {
               return reasonList[a.reason_type] - reasonList[b.reason_type]
            }
        })
    }, [reasons])

    const refresh = async () => {
        const { data } = await routes.consultationReasons.list()
        setReasons(data)
    }

    const deleteReasonHandler = async (reason) => {
        if (window.confirm('Confirm to remove cancel reason?')) {
            await routes.consultationReasons.delete(reason.id)
            refresh()
        }
    }

    const onReasonEditClickHandler = (reason) => {
        setEditingReason(reason)
    }

    const onReasonUpdateHandler = () => {
        closeReasonDialog()
        refresh()
    }

    const onReasonCreateHandler = () => {
        closeReasonDialog()
        refresh()
    }

    const closeReasonDialog = () => {
        setEditingReason(null)
        setCreatingReason(false)
    }

    useEffect(() => {
        if (shouldRefresh.current) {
            shouldRefresh.current = false
            refresh()
        }
    })

    return (
        <Box mt={3}>
            <Paper variant="outlined">
                <Box p={3}>
                    <Grid container spacing={3} alignContent="center">
                        <Grid item xs>
                            <Button 
                                color="inherit"
                                startIcon={<AddIcon />}
                                onClick={() => setCreatingReason(true)}
                            >Add Reason</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Card variant="outlined">
                        <ReasonTable
                            reasons={sortedReasons}
                            onDeleteClick={deleteReasonHandler}
                            onReasonEditClick={onReasonEditClickHandler}
                        />
                    </Card>
                </Grid>
            </Grid>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={!!editingReason}
                onClose={() => setEditingReason(null)}
            >
                {editingReason &&
                    <>
                        <Box p={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => setEditingReason(null)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <ReasonForm
                                onCancelClick={() => setEditingReason(null)}
                                consultationReason={editingReason}
                                onReasonUpdated={onReasonUpdateHandler}
                            />
                        </Box>
                    </>
                }
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={creatingReason}
                onClose={() => setCreatingReason(false)}
            >
                {creatingReason && 
                    <>
                        <Box p={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => setCreatingReason(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <ReasonForm
                                onCancelClick={() => setCreatingReason(false)}
                                consultationReason={null}
                                onReasonCreated={onReasonCreateHandler}
                            />
                        </Box>
                    </>
                }
            </Dialog>
        </Box>
    )
}

export default ConsultationReasonList