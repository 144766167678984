import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { 
    Box, 
    Grid,
    Button,
} from '@material-ui/core'

import RoomNameCircle from '../../components/AdminRoom/RoomNameCircle'
import WaitingRoom from '../../components/AdminRoom/WaitingRoom/WaitingRoom'

import api from '../../api'
import useWebSocket from '../../hooks/useWebSocket'
import useStream from '../../hooks/useStream'

function AdminRoom({ room }) {
  const history = useHistory()
  const {
    connectWebSocket,
    disconnectWebSocket,
    subscribeOnOpen,
    unsubscribeOnOpen,
    subscribeOnClose,
    unsubscribeOnClose,
    subscribeOnError,
    unsubscribeOnError,
    sendWebsocketMessage,
  } = useWebSocket()
  const { closeStream } = useStream()

  function onSocketClosedHandler(e) {
    switch (e.code) {
      case 1005:
        alert('Error occurred! Duplicated connection on the same user.')
        break
      case 1006:
        alert('Error occurred! Abnormal close.')
        break
      default:
    }

    closeStream()
    disconnectWebSocket()
    history.push('/admin')
  }

  function onSocketErrorHandler(e) {
    closeStream()
    disconnectWebSocket()
    alert('Error occurred on connection')
    history.push('/admin')
  }

  function onSocketOpenedHandler(e) {
    sendWebsocketMessage(api.ws.newGetHostCodeIntent())
    sendWebsocketMessage(api.ws.newGetIceServersConfig())
  }

  useEffect(() => {
    subscribeOnOpen('AdminRoom', onSocketOpenedHandler)
    subscribeOnClose('AdminRoom', onSocketClosedHandler)
    subscribeOnError('AdminRoom', onSocketErrorHandler)

    if (!room.key) {
      history.push('/admin')
    } else {
      connectWebSocket(room.key)
    }

    return () => {
      closeStream()
      disconnectWebSocket()
      unsubscribeOnOpen('AdminRoom')
      unsubscribeOnClose('AdminRoom')
      unsubscribeOnError('AdminRoom')
    }
  })

  if (!room.id) {
    history.push('/admin')
  }

  return (
    <>
      <Box mt={1}>
        <Grid container spacing={3} justify="center" alignItems="flex-start">
          <Grid item>
            <Box>
              <RoomNameCircle
                color={room.color}
              >{room.name}</RoomNameCircle>
            </Box>
          </Grid>
          <Grid item xs>
            <WaitingRoom room={room} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default connect(state => ({
  room: state.room,
}))(AdminRoom)