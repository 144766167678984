import React, { useState, useRef, useEffect } from 'react'
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import routes from '../../api/routes'

import MaterialList from './MaterialList'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    mountOnEnter
    unmountOnExit
    {...props}
  />
})

function MaterialGroupList(props) {
  const {
    room,
    onCloseClick,
  } = props
  const init = useRef(true)
  const [title, setTitle] = useState('')
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)

  const refresh = async () => {
    try {
      const { data } = await routes.materialGroups.list(room.id)
      setGroups(data)
    } catch (e) {
      alert('Error occurs when getting material group list')
    }
  }

  const addMaterialGroup = async () => {
    try {
      await routes.materialGroups.create(room.id, title)
      setTitle('')
      refresh()
    } catch (e) {
      alert('Error occurs when creating a new material group')
    }
  }

  const editGroups = (group) => {
    setSelectedGroup(group)
  }

  const deleteGroups = async (group) => {
    if (!window.confirm('Confirm to remove a material group')) {
      return
    }

    try {
      await routes.materialGroups.remove(group.id)
      refresh()
    } catch (e) {
      alert('Error occurs when deleting the material group')
    }
  }

  const closeMaterialList = () => {
    setSelectedGroup(null)
    refresh()
  }

  useEffect(() => {
    if (init.current) {
      init.current = false
      refresh()
    }
  })

  return (
    <>
      <AppBar style={{ position: 'relative', backgroundColor: room.color }}>
        <Toolbar>
          <Typography
            variant="h5"
            style={{ flex: '1 1 100%' }}
          >{room.name} Materials</Typography>
          <IconButton style={{ color: 'white' }} onClick={refresh}>
            <RefreshIcon />
          </IconButton>
          <IconButton style={{ color: 'white' }} onClick={() => onCloseClick()}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box mt={3}></Box>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      placeholder="Material Group Title"
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                    ></TextField>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<AddCircleIcon />}
                      disabled={!title.length}
                      onClick={addMaterialGroup}
                    >Add Material Group</Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper variant="outlined">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell
                        align="center"
                        style={{whiteSpace:'nowrap', width: '1%'}}
                      >Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { groups.map(g => {
                      return <TableRow>
                        <TableCell>{g.title}</TableCell>
                        <TableCell
                          align="center"
                          style={{whiteSpace:'nowrap', width: '1%'}}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => editGroups(g)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => deleteGroups(g)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        fullScreen
        open={!!selectedGroup}
        TransitionComponent={Transition}
        onClose={closeMaterialList}
      >
        { selectedGroup && <MaterialList
            group={selectedGroup}
            onCloseClick={closeMaterialList}
          />
        }
      </Dialog>
    </>
  )
}

export default MaterialGroupList
