export default function userReducer(state = {
  id: 0,
  username: '',
  name: '',
  roles: [],
}, action) {
  switch (action.type) {
    case 'UPDATE_USER_INFO':
      return {
        id: action.id,
        username: action.username,
        name: action.name,
        roles: action.roles,
      }
    case 'LOGOUT':
      return {
        id: 0,
        username: '',
        name: '',
        roles: [],
      }
    default:
      return state
  }
}
