import { useTranslation } from 'react-i18next'
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core'

export default function CautionList() {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h5" align="center">
        <Divider />
        <Box mb={1} mt={3}>
          <b>{t('precautions.title')}</b>
        </Box>
      </Typography>
      <ol style={{padding: '0 16px'}}>
        <li><Typography variant="body1">{t('precautions.1')}</Typography></li>
        <li><Typography variant="body1">{t('precautions.2')}</Typography></li>
        <li><Typography variant="body1">{t('precautions.3')}</Typography></li>
        <li><Typography variant="body1">{t('precautions.4')}</Typography></li>
        <li><Typography variant="body1">{t('precautions.5')}</Typography></li>
        <li><Typography variant="body1">{t('precautions.6')}</Typography></li>
        <li><Typography variant="body1">{t('precautions.7')}</Typography></li>
      </ol>
    </>
  )
}