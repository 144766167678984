import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Redirect,
} from 'react-router-dom'

function AuthRoute({ username, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        username !== ''
        ? children
        : <Redirect to={{ pathname: '/admin/login', state: { from: location } }} />
      }
    />
  )
}

export default connect(state => ({
  username: state.user.username,
}))(AuthRoute)