import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
    Grid,
    Typography,
    Slide,
    Dialog,
  } from '@material-ui/core'
import ColoredButton from '../../components/ColoredButton'

import CancelReasonList from '../../components/AdminSetting/CancelReasonList'
import VitalTemplateList from '../../components/AdminSetting/VitalTemplateList'
import LeafletList from '../../components/AdminSetting/LeafletList'
import ConsultationReasonList from '../../components/AdminSetting/ConsultationReasonList'
import DialogContent from '../../components/DialogContent'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function SettingList({ onButtonClick }) {
    return (
        <>
            <Grid item xs={12} key={'cancel'}>
                <ColoredButton
                    color="green"
                    textColor="white"
                    variant="contained"
                    fullWidth
                    onClick={() => { onButtonClick('cancel') }}
                >
                    <Typography variant="h2">Cancel Reason</Typography>
                </ColoredButton>
            </Grid>
            <Grid item xs={12} key={'vital'}>
                <ColoredButton
                    color="orange"
                    textColor="white"
                    variant="contained"
                    fullWidth
                    onClick={() => { onButtonClick('vital') }}
                >
                    <Typography variant="h2">Vital Template</Typography>
                </ColoredButton>
            </Grid>
            <Grid item xs={12} key={'leaflet'}>
                <ColoredButton
                    color="blue"
                    textColor="white"
                    variant="contained"
                    fullWidth
                    onClick={() => { onButtonClick('leaflet') }}
                >
                    <Typography variant="h2">Leaflet</Typography>
                </ColoredButton>
            </Grid>
            <Grid item xs={12} key={'consultation'}>
                <ColoredButton
                    color="purple"
                    textColor="white"
                    variant="contained"
                    fullWidth
                    onClick={() => { onButtonClick('consultation') }}
                >
                    <Typography variant="h2">Consultation Reason</Typography>
                </ColoredButton>
            </Grid>
        </>
    )
}

function AdminSettingManagement() {
    const [showCancelReason, setShowCancelReason] = useState(false)
    const [showVitalTemplate, setShowVitalTemplate] = useState(false)
    const [showLeaflet, setShowLeaflet] = useState(false)
    const [showConsultationReason, setShowConsultationReason] = useState(false)

    function selectSetting(setting) {
        switch (setting) {
            case 'cancel':
                setShowCancelReason(true)
                break
            case 'vital':
                setShowVitalTemplate(true)
                break
            case 'leaflet':
                setShowLeaflet(true)
                break
            case 'consultation':
                setShowConsultationReason(true)
                break
            default:
                break
        }
    }

    return (
        <>
            <Grid container spacing={3}>
                <SettingList
                    onButtonClick={(setting) => { selectSetting(setting) }}
                >
                </SettingList>
            </Grid>

            <Dialog
                fullScreen
                open={showCancelReason}
                onClose={() => setShowCancelReason(false)}
                TransitionComponent={Transition}
            >
                <DialogContent
                    title="Cancel Reason"
                    color={'green'}
                    onClose={() => setShowCancelReason(false)}
                >
                    <CancelReasonList />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={showVitalTemplate}
                onClose={() => setShowVitalTemplate(false)}
                TransitionComponent={Transition}
            > 
                <DialogContent
                    title="Vital Template"
                    color={'orange'}
                    onClose={() => setShowVitalTemplate(false)}
                >
                    <VitalTemplateList />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={showLeaflet}
                onClose={() => setShowLeaflet(false)}
                TransitionComponent={Transition}
            >
                <DialogContent
                    title="Leaflet"
                    color={'blue'}
                    onClose={() => setShowLeaflet(false)}
                >
                    <LeafletList />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={showConsultationReason}
                onClose={() => setShowConsultationReason(false)}
                TransitionComponent={Transition}
            >
                <DialogContent
                    title="Consultation Reason"
                    color={'purple'}
                    onClose={() => setShowConsultationReason(false)}
                >
                    <ConsultationReasonList />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default connect(state => ({
    roles: state.user.roles,
}))(AdminSettingManagement)