import React, { useEffect, useState, useRef } from 'react'
import dayjs from 'dayjs'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'

import api from '../../../api'
import PatientTableCounterCell from './PatientTableCounterCell'
import useWebSocket from '../../../hooks/useWebSocket'

function PatientTableRows({ patientList, onChatClick }) {
  return patientList.map(p => {
    const now = dayjs()
    const dob = dayjs(p.dob, 'YYYY-MM-DD')
    const age = now.diff(dob, 'year')
    const bookingAt = dayjs(p.time)
    const bookingTime = bookingAt.format('HH:mm')
    const connectedAt = dayjs(p.connectedAt)

    return (
      <TableRow key={p.id + p.sopd}>
        <TableCell>{p.sopd}</TableCell>
        <TableCell>{p.name}</TableCell>
        <TableCell align="center">{age}/{p.gender.toUpperCase()}</TableCell>
        <TableCell align="center">{bookingTime}</TableCell>
        <PatientTableCounterCell
          connectedAt={connectedAt}
        ></PatientTableCounterCell>
        <TableCell align="center">
          <Box display="flex" justifyContent="space-around">
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => onChatClick(p)}
            >Chat</Button>
          </Box>
        </TableCell>
      </TableRow>
    )
  })
}

export default function PatientTable(props) {
  const { onChatClick } = props
  const [patientList, setPatientList] = useState([])
  const fetchedPatientList = useRef(false)
  const {
    subscribeOnMessage,
    unsubscribeOnMessage,
    sendWebsocketMessage,
  } = useWebSocket()

  function onSocketMessage(e) {
    switch (e.intent) {
      case 'updatePatientList':
        setPatientList(e.data.patientList)
        break
      default:
    }
  }

  useEffect(() => {
    subscribeOnMessage('patientTable', onSocketMessage)

    if (!fetchedPatientList.current) {
      fetchedPatientList.current = true
      sendWebsocketMessage(api.ws.newGetPatientListIntent())
    }

    return () => unsubscribeOnMessage('patientTable')
  })

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>SOPD/HN</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Age/Gender</TableCell>
              <TableCell align="center">Booking At</TableCell>
              <TableCell align="center">Waiting Time</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PatientTableRows
              patientList={patientList}
              onChatClick={onChatClick}
            ></PatientTableRows>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}