import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export default function DialogContent(props) {
  const {
    title,
    color,
    onClose,
    children,
  } = props

  return (
    <>
      <AppBar style={{position: 'relative', backgroundColor: color}}>
        <Toolbar>
          <Typography variant="h6" style={{flexGrow: 1}}>{title}</Typography>
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={3}>
          {children}
        </Box>
      </Container>
    </>
  )
}