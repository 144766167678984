import React from 'react'
import { connect } from 'react-redux'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles({
    container: {
        maxHeight: 440,
    },
})

function TemplateTable(props) {
    const {
        templates, 
        onDeleteClick,
        onTemplateEditClick,
    } = props
    const classes = useStyles()
    if (!templates) return false

    const rows = templates.map((template, index) => {
        return (
            <TableRow key={template.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{template.label}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => onTemplateEditClick(template)} color="primary" size="small">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteClick(template)} color="secondary" size="small">
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    })

    return (
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="center">Label</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      )
}

export default connect(state => ({
    roles: state.user.roles,
}))(TemplateTable)