import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../api'
import utils from '../../utils'
import useStream from '../../hooks/useStream'
import usePeerConnection from '../../hooks/usePeerConnection'

import ChatScreen from './ChatScreen'
import WaitingScreen from './WaitingScreen'
import useWebSocket from '../../hooks/useWebSocket'

export default function ChatRoom(props) {
  const {
    patient,
    room,
    sender,
    setSocketClosePage,
  } = props

  const history =  useHistory()
  const [iceServersConfig, setIceServersConfig] = useState(null)
  const receiver = useRef(null)
  const initializedWebSocket = useRef(false)
  const [forceUpdateSwitch, setForceUpdateSwitch] = useState(false)
  const [showSurvey, setShowSurvey] = useState(false)
  const {
    getStream,
    openStream,
    closeStream,
  } = useStream()
  const {
    getPeerConnection,
    remoteStream,
    initPeerConnection,
    closePeerConnection,
    subscribeOnConnectionStateChange,
    unsubscribeOnConnectionStateChange,
    createAnswer,
    addIceCandidate,
  } = usePeerConnection()
  const {
    connectWebSocket,
    disconnectWebSocket,
    subscribeOnMessage,
    unsubscribeOnMessage,
    sendWebsocketMessage,
  } = useWebSocket()
  const [messages, setMessages] = useState([])
  // This flag is used to prevent iOS Safari
  // change page before received the on hold socket message
  const shouldGoToRoot = useRef(false)

  useEffect(() => {
    subscribeOnMessage('chatRoom', onSocketMessage)
    subscribeOnConnectionStateChange('chatRoom', onConnectionStateChange)
    if (room.key && sender && !initializedWebSocket.current) {
      initializedWebSocket.current = true
      connectWebSocket(room.key, sender)
    }

    return () => {
      unsubscribeOnMessage('chatRoom')
      unsubscribeOnConnectionStateChange('onConnectionStateChange')
    }
  })

  function addMessage(sender, message) {
    const newMessages = [...messages]

    newMessages.push({
      code: sender,
      msg: message,
    })

    setMessages(newMessages)
  }

  function onSocketMessage(e) {
    switch (e.intent) {
      case api.ws.intents.getIceServersConfig:
        setIceServersConfig(e.data.config)
        break
      case api.ws.intents.sendInvitation:
        init(e)
        break
      case api.ws.intents.sendOffer:
        receiver.current = e.sender
        createPeerAnswer(e)
        break
      case api.ws.intents.sendIceCandidate:
        addIceCandidateToPeer(new RTCIceCandidate(e.data.iceCandidate))
        break
      case api.ws.intents.putOnHold:
        shouldGoToRoot.current = false
        disconnect()
        break
      case api.ws.intents.doneInspection:
        disconnect('/done')
        disconnectWebSocket()
        history.push('/done')
        break
      case api.ws.intents.showSurvey:
        setShowSurvey(true)
        break
      case api.ws.intents.textMessage:
        addMessage(e.sender, e.data.message)
        break
      default:
    }
  }

  async function init(sm) {
    await openStream()
    initPeerConnection(iceServersConfig, getStream(),  sender, sm.sender)
    receiver.current = sm.sender
    sendWebsocketMessage(api.ws.newSendReplyIntent(sender, sm.sender))
  }

  function addIceCandidateToPeer(iceCandidate) {
    if (!getPeerConnection().remoteDescription) {
      return setTimeout(() => {
        addIceCandidateToPeer(iceCandidate)
      }, 250)
    }

    addIceCandidate(iceCandidate)
  }

  async function createPeerAnswer(sm) {
    await createAnswer(sm.data.offer)
  }

  function onConnectionStateChange(state) {
    switch(state) {
      case 'connected':
        setTimeout(() => utils.setMaxBitRate(getPeerConnection()), 2000)
        break
      case 'disconnected':
      case 'failed':
      case 'closed':
        shouldGoToRoot.current = true
        setTimeout(() => {
          if (shouldGoToRoot.current) {
            disconnect()
            history.push('/')
          }
        }, 2000)
        break
      default:
    }
  }

  function disconnect(to = '') {
    if (to) {
      setSocketClosePage(to)
    }

    receiver.current = null

    closeStream()
    closePeerConnection()

    if (!to) {
      setForceUpdateSwitch(!forceUpdateSwitch)
    }
  }

  function onSendMessageHandler(sender, receiver, msg) {
    sendWebsocketMessage(api.ws.newTextMessageIntent(sender, receiver.current, msg))
    addMessage(sender, msg)
  }

  if (!receiver.current) {
    return <WaitingScreen
      room={room}
      patient={patient}
      receiver={receiver}
      messages={messages}
      setMessages={setMessages}
      onSendMessage={onSendMessageHandler}
    />
  }

  return <ChatScreen
    room={room}
    remoteStream={remoteStream}
    patient={patient}
    messages={messages}
    showSurvey={showSurvey}
    setShowSurvey={setShowSurvey}
    onSendMessage={onSendMessageHandler}
  />
}