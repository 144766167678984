import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Box,
  LinearProgress,
  Container,
} from '@material-ui/core'

import logo from '../assets/logo.jpeg'
import api from '../api'
import actions from '../store/actions'
import ColoredButton from './ColoredButton'
import { connect } from 'react-redux'

import BirthdayVerify from './PatientVerify/BirthdayVerify'
import GenderVerify from './PatientVerify/GenderVerify'
import HKIDVerify from './PatientVerify/HKIDVerify'

const useStyles = makeStyles({
  paper: ({ color }) => {
    return {
      borderStyle: 'dashed',
      borderColor: color,
    }
  },
  submit: ({ color }) => ({
    color: 'white',
    backgroundColor: color,
  }),
})

function PatientLogin({ code, booking, dispatch }) {
  const history = useHistory()
  const { t }  = useTranslation()
  //const [gender, setGender] = useState('')
  const [hkid, setHKID] = useState('')
  const [year, setYear] = useState(2000)
  const [month, setMonth] = useState(1)
  const [day, setDay] = useState(1)
  const [room, setRoom] = useState(null)
  const classes = useStyles({ color: room ? room.color : 'black' })

  const disableSubmitBtn = useMemo(() => {
    if (!hkid) return true
    if (hkid.length !== 4) return true
    if (!day) return true
    if (!month) return true
    if (!year) return true

    return false
  }, [hkid, day, month, year])

  useEffect(() => {
    if (booking && !room) {
      api.routes.patients.room(code)
        .then(res => {
          setRoom(res.data)
        })
        .catch(console.log)
    }
  })

  async function submitHandler(e) {
    e.preventDefault()
    const dob = dayjs(new Date(year, month - 1, day)).format('YYYY-MM-DD')

    try {
      //const { data } = await api.routes.patients.login(code, dob, gender)
      const { data } = await api.routes.patients.login(code, dob, hkid)
      dispatch(actions.updateRoom(
        room.id,
        room.name,
        room.key,
        room.interval,
        room.color,
        room.surveyLink,
      ))
      dispatch(actions.updatePatientInfo(
        data.id,
        data.sopd,
        data.name,
        data.gender,
        data.dob,
        data.time,
        data.code,
        data.status,
      ))
      history.replace('/room')
    } catch (err) {
      alert(t('noAppointment'))
    }
  }

  if (!room || !booking) {
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Box pt={5} display="flex" justifyContent="center">
              <img alt="Logo" src={logo} style={{ width: '100%' }}></img>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <Grid container>
      <Grid item xs={1} md={1} lg={3} />
      <Grid item xs={10} md={10} lg={6}>
        <Paper elevation={0} classes={{ root: classes.paper }}>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  align="center"
                  style={{ color: room ? room.color : 'black' }}
                >{room && room.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                >Hello. 您好! {booking.name}</Typography>
                <Typography
                  variant="h6"
                >請輸入<span style={{ fontWeight: '700'}}>生日日期</span>及<span style={{ fontWeight: '700'}}>身份證頭四個數字</span>以核實身份</Typography>
              </Grid>
              <Grid item xs={12}>
                <form noValidate onSubmit={submitHandler}>
                  <Grid container spacing={3}>
                    <Grid container item xs={12}>
                      <BirthdayVerify 
                        year={year}
                        setYear={setYear}
                        month={month}
                        setMonth={setMonth}
                        day={day}
                        setDay={setDay}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <HKIDVerify
                        HKID={hkid}
                        setHKID={setHKID}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ColoredButton
                          disableElevation
                          type="submit"
                          variant="contained"
                          disabled={disableSubmitBtn}
                          color={room.color}
                          textColor={disableSubmitBtn ? '' : 'white'}
                        >
                          <Typography
                            variant="subtitle1"
                          >Enter 進入</Typography>
                        </ColoredButton>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default connect()(PatientLogin)