import { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Bowser from 'bowser'

import CodeEnter from '../components/CodeEnter'
import PatientLogin from '../components/PatientLogin'
import Support from '../components/Support'

function Home({ username }) {
  const history = useHistory()
  const [code, setCode] = useState('')
  const [booking, setBooking] = useState(null)

  const checkCamera = useRef(false)
  const [allowCamera, setAllowCamera] = useState(null)
  const browser = Bowser.getParser(window.navigator.userAgent)
  const isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1
  const constrains = isIpad ? {
    macos: {
      safari: '>= 11',
    },
  } : {
    windows: {
      chrome: '>= 56',
      firefox: '>= 44',
      edge: '>= 79',
      safari: '>= 11',
    },
    macos: {
      chrome: '>= 56',
      firefox: '>= 44',
      edge: '>= 79',
      safari: '>= 11',
    },
    linux: {
      chrome: '>= 56',
      firefox: '>= 44',
    },
    ios: {
      safari: '>= 11',
    },
    android: {
      chrome: '>= 56',
      firefox: '>= 44',
    },
  }
  const isValidBrowser = browser.satisfies(constrains)

  function init() {
    if (!checkCamera.current) {
      checkCamera.current = true

      if (!('mediaDevices' in navigator)) {
        setAllowCamera(false)
      } else {
        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        }).then(stream => {
          stream.getTracks().forEach(t => t.stop())
          setAllowCamera(true)
        }).catch(err => {
          setAllowCamera(false)
        })
      }
    }
  }

  useEffect(() => {
    init()
  })

  if (username) {
    history.push('/admin')
  }

  if (!checkCamera.current) {
    return false
  }

  if (!isValidBrowser || !allowCamera) {
    return <Support
      isValidBrowser={isValidBrowser}
      allowCamera={allowCamera}
    />
  }

  if (!code) {
    return <CodeEnter
      onCodeSet={code => setCode(code)}
      onBookingSet={setBooking}
    />
  }

  return <PatientLogin
    code={code}
    booking={booking}
  />
}

export default connect(state => ({
  username: state.user.username,
}))(Home)