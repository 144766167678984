import React from 'react'
import { connect } from 'react-redux'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles({
    container: {
        maxHeight: 440,
    },
})

function LeafletTable(props) {
    const {
        leaflets,
        onDeleteClick,
        onViewClick,
    } = props
    const classes = useStyles()
    if (!leaflets) return false

    const rows = leaflets.map((leftlet, index) => {
        return (
            <TableRow key={leftlet.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{leftlet.name}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => onViewClick(leftlet)} color="primary" size="small">
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteClick(leftlet)} color="secondary" size="small">
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    })

    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="center">Label</TableCell>
                        <TableCell align="center">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{rows}</TableBody>
            </Table>
        </TableContainer>
    )
}

export default connect(state => ({
    roles: state.user.roles,
})) (LeafletTable)