import Bowser from 'bowser'
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Box,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import chrome from '../assets/browser_icons/chrome.png'
import safari from '../assets/browser_icons/safari.png'
import firefox from '../assets/browser_icons/firefox.png'
import edge from '../assets/browser_icons/edge.png'

export default function Support(props) {
  const {
    isValidBrowser,
    allowCamera,
  } = props

  const browser = Bowser.getParser(window.navigator.userAgent)

  if (!isValidBrowser) {
    return (
      <>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">你正在使用 { browser.getOSName() } 的 { browser.getBrowserName() }</Typography>
                <Typography variant="h4" align="center">你所使用的瀏覽器並不支援！</Typography>
                <Typography variant="h4" align="center">This browser is not supported!</Typography>
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </Grid>
            <Box mt={3} style={{overflow: 'scroll'}}>
              <table id="support-table">
                <thead>
                  <tr>
                    <th></th>
                    <th align="center">Windows</th>
                    <th align="center">Linux</th>
                    <th align="center">Android Phone</th>
                    <th align="center">Android Tablet</th>
                    <th align="center">macOS</th>
                    <th align="center">iPhone</th>
                    <th align="center">iPad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={chrome} alt="chrome" />
                        <span>Chrome</span>
                      </Box>
                    </th>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={firefox} alt="firefox" />
                        <span>FireFox</span>
                      </Box>
                    </th>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={safari} alt="safari" />
                        <span>Safari</span>
                      </Box>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                    <td><CheckCircleIcon /></td>
                  </tr>
                  <tr>
                    <th>
                      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                        <img src={edge} alt="edge" />
                        <span>Edge</span>
                      </Box>
                    </th>
                    <td><CheckCircleIcon /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><CheckCircleIcon /></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </CardContent>
        </Card>
      </>
    )
  }

  if (!allowCamera) {
    return (
      <Container>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs>
                <Typography variant="h4" align="center">
                  你所用的設備或瀏覽器並沒有支援攝像鏡頭，或你並沒有允許使用！
                </Typography>
                <Typography variant="h4" align="center">
                  The device or browser you are using does not have a supported camera, or you do not allow to use it.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    )
  }

  return false
}