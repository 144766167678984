import React, { useState, useRef, useEffect } from 'react'
import {
    Box,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableContainer,
    Radio,
    Button,
  } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import api from '../../api'

function ScoreRadioBox({ 
    onRadioClick,
    groupValue,
    score,
}) {
    return (
        <Radio
            checked={groupValue === score}
            onChange={() => { onRadioClick(score) }}
        />
    )
}

function RadioGroupRow({
    onValueChangeHandler,
    questionIndex,
    values,
}) {
    return (
        <TableRow>
            <TableCell style={{ width: '10%', padding: 0}} align="center"></TableCell>
            <TableCell style={{ width: '5%', padding: 0}} align="center">
                <ScoreRadioBox
                    onRadioClick={(score) => { onValueChangeHandler(score, questionIndex) }}
                    groupValue={values[questionIndex]}
                    score={5}
                />
            </TableCell>
            <TableCell style={{ width: '5%', padding: 0}} align="center">
                <ScoreRadioBox
                    onRadioClick={(score) => { onValueChangeHandler(score, questionIndex) }}
                    groupValue={values[questionIndex]}
                    score={4}
                />
            </TableCell>
            <TableCell style={{ width: '5%', padding: 0}} align="center">
                <ScoreRadioBox
                    onRadioClick={(score) => { onValueChangeHandler(score, questionIndex) }}
                    groupValue={values[questionIndex]}
                    score={3}
                />
            </TableCell>
            <TableCell style={{ width: '5%', padding: 0}} align="center">
                <ScoreRadioBox
                    onRadioClick={(score) => { onValueChangeHandler(score, questionIndex) }}
                    groupValue={values[questionIndex]}
                    score={2}
                />
            </TableCell>
            <TableCell style={{ width: '5%', padding: 0}} align="center">
                <ScoreRadioBox
                    onRadioClick={(score) => { onValueChangeHandler(score, questionIndex) }}
                    groupValue={values[questionIndex]}
                    score={1}
                />
            </TableCell>
            <TableCell style={{ width: '10%', padding: 0}} align="center"></TableCell>
        </TableRow>
    )
}

function QuestionTable({
    onValueChangeHandler,
    questionIndex,
    values,
    questionString,
    t,
}) {
    return (
        <Grid item xs={12} sm={12} md={12}>
            <Box p={1}></Box>
            <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h5" align="center">{questionString}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Paper variant="outlined">
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.highlyagree')}</TableCell>
                                        <TableCell style={{ width: '5%', padding: 0}} align="center">5</TableCell>
                                        <TableCell style={{ width: '5%', padding: 0}} align="center">4</TableCell>
                                        <TableCell style={{ width: '5%', padding: 0}} align="center">3</TableCell>
                                        <TableCell style={{ width: '5%', padding: 0}} align="center">2</TableCell>
                                        <TableCell style={{ width: '5%', padding: 0}} align="center">1</TableCell>
                                        <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.highlydisagree')}</TableCell>
                                        {/* <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.highlyagree')}</TableCell>
                                        <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.agree')}</TableCell>
                                        <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.normal')}</TableCell>
                                        <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.disagree')}</TableCell>
                                        <TableCell style={{ width: '10%', padding: 0}} align="center">{t('survey.score.highlydisagree')}</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <RadioGroupRow
                                        onValueChangeHandler={onValueChangeHandler}
                                        questionIndex={questionIndex}
                                        values={values}
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
            <Box p={1}></Box>
        </Grid>
    )
}

export default function Survey(props) {
    const {
        code,
        setShowSurvey,
    } = props
    const { t } = useTranslation()

    const [surveySent, setSent] = useState(false)

    const [surveyValue, setValue] = useState({})
    const initSurvey = useRef(false)

    function fetchSurvey() {
        if (!initSurvey.current) {
            initSurvey.current = true

            api.routes.surveys.get(code).then(res => {
                if (res.data.status === 'not found') {
                    setSent(false)
                }
                else {
                    setSent(true)
                }
            }).catch(err => {
                alert('Error occurs when getting survey information')
            })
        }
    }

    function changeValue(score, index) {
        const value = {...surveyValue}
        value[index] = score
        setValue(value)
    }

    function submitHandler(e) {
        e.preventDefault()

        if (!surveyValue[1] || !surveyValue[2] || !surveyValue[3]) {
            alert(t('survey.missinganswer'))
            return
        }

        api.routes.surveys.create(code, JSON.stringify(surveyValue)).then(res => {
            setSent(true)
            setShowSurvey(false)
        }).catch(err => {
            alert('Error occurs when submitting survey')
        })
    }

    function cancelHandler(e) {
        e.preventDefault()

        setShowSurvey(false)
    }

    useEffect(() => {
        fetchSurvey()
    })

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h4" align="center">{t('survey.question.topic')}</Typography>
                </Box>
            </Grid>
            <QuestionTable
                onValueChangeHandler={changeValue}
                questionString={t('survey.question.1')}
                questionIndex={1}
                values={surveyValue}
                t={t}
            />
            <QuestionTable
                onValueChangeHandler={changeValue}
                questionString={t('survey.question.2')}
                questionIndex={2}
                values={surveyValue}
                t={t}
            />
            <QuestionTable
                onValueChangeHandler={changeValue}
                questionString={t('survey.question.3')}
                questionIndex={3}
                values={surveyValue}
                t={t}
            />
            <Grid item xs={12}>
                <Button
                color="primary"
                variant="outlined"
                fullWidth
                onClick={submitHandler}
                >{t('submit')}</Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={cancelHandler}
                >{t('cancel')}</Button>
            </Grid>
        </Grid>
    )
}