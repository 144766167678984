import React, { useState, useEffect, useMemo } from 'react'
import {
  Grid,
  Box,
  Slide,
  Dialog,
  Button,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import TextsmsIcon from '@material-ui/icons/Textsms'
import PostAddIcon from '@material-ui/icons/PostAdd'
import ScheduleIcon from '@material-ui/icons/Schedule'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

import { ws } from '../../../api'
import useWebSocket from '../../../hooks/useWebSocket'
import utils from '../../../utils'

import TabPanel from '../TabPanel'
import PatientTable from './PatientTable'
import ChatRoom from './ChatRoom'
import MessengerPanel from '../../MessengerPanel'
import DialogContent from '../../DialogContent'
import BookingForm from '../BookingForm'
import Schedules from '../Schedules/Schedules'
import ScheduleList from '../ScheduleList'
import { connect } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function WaitingRoom(props) {
  const { room, roles } = props
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState('patientTable')
  const [hostCode, setHostCode] = useState(null)
  const [patient, setPatient] = useState(null)
  const [iceServersConfig, setIceServersConfig] = useState(null)
  const {
    subscribeOnMessage,
    unsubscribeOnMessage,
    sendWebsocketMessage,
  } = useWebSocket()
  const [showMessengerPanel, setShowMessengerPanel] = useState(false)
  const [showPatientRegistration, setShowPatientRegistration] = useState(false)
  const [showSchedule, setShowSchedule] = useState(true)
  const [showScheduleList, setShowScheduleList] = useState(false)
  const [patientList, setPatientList] = useState([])
  const [messages, setMessages] = useState({}) // key by patient code, value is array

  useEffect(() => {
    subscribeOnMessage('waitingRoom', onSocketMessage)
    return () => unsubscribeOnMessage('waitingRoom')
  })

  const shouldShowGroupEducation = useMemo(() => {
    if (!room) return false
    if (room.key === utils.constants.room_identifier.surgery_school) return true
    if (room.key === utils.constants.room_identifier.npac) return true
    return false
  }, [room])

  const groupEducationLabel = useMemo(() => {
    if (!room) return ''
    if (room.key === utils.constants.room_identifier.surgery_school) return 'Group Education'
    if (room.key === utils.constants.room_identifier.npac) return 'Number of Patient Attendance'
  }, [room])

  function onSocketMessage(e) {
    switch (e.intent) {
      case ws.intents.getHostCode:
        setHostCode(e.data.code)
        break
      case ws.intents.getIceServersConfig:
        setIceServersConfig(e.data.config)
        break
      case ws.intents.updatePatientList:
        updatePatientListAndMessages(e.data.patientList)
        break
      case ws.intents.textMessage:
        addMessage(e.sender, e.sender, e.data.message)
        break
      default:
    }
  }

  function addMessage(queue, sender, message) {
    const newMessages = { ...messages }

    newMessages[queue].push({
      code: sender,
      msg: message,
    })

    setMessages(newMessages)
  }

  function sendMessage(sender, receiver, message) {
    sendWebsocketMessage(ws.newTextMessageIntent(sender, receiver, message))
    addMessage(receiver, sender, message)
  }

  function updatePatientListAndMessages(patientList) {
    const patientCodes = patientList.map(p => p.code)
    const messagesCodes = Object.keys(messages)

    if (patientCodes.length === messagesCodes.length) {
      return
    }

    const newMessages = {}
    patientList.forEach(patient => {
      newMessages[patient.code] = patient.code in messages
        ? messages[patient.code]
        : []
    })

    setMessages(newMessages)
    setPatientList(patientList)
  }

  function onChatClick(patient) {
    setPatient(patient)
    setSelectedTab('chatRoom')
  }

  function toPatientTable() {
    setSelectedTab('patientTable')
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="static" style={{ backgroundColor: room.color }}>
            <Toolbar variant="dense">
              <Box display="flex" flexGrow={1}>
                {shouldShowGroupEducation && (
                  <Button
                    color="inherit"
                    startIcon={<EventAvailableIcon />}
                    onClick={() => history.push('/admin/group_education')}
                  >{groupEducationLabel}</Button>
                )}
                {(utils.isSuper(roles) || utils.isNormal(roles)) &&
                  <Button
                    color="inherit"
                    startIcon={<PostAddIcon />}
                    onClick={() => setShowPatientRegistration(true)}
                  >Patient Registration</Button>
                }
                <Button
                  color="inherit"
                  startIcon={<CalendarTodayIcon />}
                  onClick={() => setShowSchedule(true)}
                >Today's List</Button>
                <Button
                  color="inherit"
                  startIcon={<ScheduleIcon />}
                  onClick={() => setShowScheduleList(true)}
                >Schedule List</Button>
              </Box>
              <Box display="flex" justifyContent="right">
                <Button
                  color="inherit"
                  startIcon={<TextsmsIcon />}
                  onClick={() => setShowMessengerPanel(true)}
                >Text Messenger</Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <TabPanel index="patientTable" value={selectedTab}>
            <PatientTable
              onChatClick={onChatClick}
            ></PatientTable>
          </TabPanel>
          <TabPanel index="chatRoom" value={selectedTab}>
            {(hostCode && patient && iceServersConfig) &&
              <ChatRoom
                room={room}
                sender={hostCode}
                receiver={patient}
                iceServersConfig={iceServersConfig}
                toPatientTable={toPatientTable}
                messages={messages}
                sendMessage={sendMessage}
              ></ChatRoom>
            }
          </TabPanel>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={showPatientRegistration}
        onClose={() => setShowPatientRegistration(false)}
        TransitionComponent={Transition}
      >
        <DialogContent
          title="Patient Registration"
          color={room.color}
          onClose={() => setShowPatientRegistration(false)}
        >
          <BookingForm room={room} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={showSchedule}
        onClose={() => setShowSchedule(false)}
        TransitionComponent={Transition}
      >
        <DialogContent
          title="Today's List"
          color={room.color}
          onClose={() => setShowSchedule(false)}
        >
          <Schedules room={room} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={showScheduleList}
        onClose={() => setShowScheduleList(false)}
        TransitionComponent={Transition}
      >
        <DialogContent
          title="Schedules List"
          color={room.color}
          onClose={() => setShowScheduleList(false)}
        >
          <ScheduleList room={room} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={showMessengerPanel}
        onClose={() => setShowMessengerPanel(false)}
        TransitionComponent={Transition}
        keepMounted
        disablePortal
      >
        <DialogContent
          title="Text Messenger"
          color={room.color}
          onClose={() => setShowMessengerPanel(false)}
        >
          <MessengerPanel
            code={hostCode}
            patientList={patientList}
            messages={messages}
            color={room.color}
            setMessages={setMessages}
            addMessage={addMessage}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default connect(state => ({
  roles: state.user.roles,
}))(WaitingRoom)