import getAxios from './axios'

const list = (name) => getAxios({
  url: '/rooms',
  method: 'GET',
  params: {
    name,
  },
})

const create = () => getAxios({
  url: '/rooms',
  method: 'POST',
})

const show = (id) => getAxios({
  url: `/rooms/${id}`,
  method: 'GET',
})

const update = (id, data = {}) => getAxios({
  url: `/rooms/${id}`,
  method: 'PATCH',
  data,
})

const destroy = (id) => getAxios({
  url: `/rooms/${id}`,
  method: 'DELETE',
})

const rooms = {
  list,
  create,
  show,
  update,
  destroy,
}

export default rooms