import React from 'react'
import {
  Box,
  Grid,
  Paper,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
    senderDialog: {
      backgroundColor: '#00e676',
      maxWidth: '100%',
    },
    receiverDialog: {
      backgroundColor: '#00b0ff',
      maxWidth: '100%',
    },
    paragraph: {
      padding: 0,
      margin: 0,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      maxWidth: '100%',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    patientDialog: {
      backgroundColor: props => props.color,
      color: 'black',
    },
    clinicDialog: {
      backgroundColor: props => props.color,
      color: 'black',
      filter: 'brightness(1.7) saturate(0.4)',
    },
  },
)

export default function MessengerDialog(props) {
  const {
    isSender,
    isPatient,
    color,
    message,
  } = props

  const classes = useStyles({
    color,
  })

  if (isSender) {
    return (
      <Box my={1}>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Box display="flex" justifyContent="flex-end">
              <Paper variant="outlined" className={ isPatient ? classes.patientDialog : classes.clinicDialog }>
                <Box display="inline-block" p={1}>
                  <pre className={classes.paragraph}>{message}</pre>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box my={1}>
      <Grid container>
        <Grid item xs={10}>
          <Box display="flex" justifyContent="start">
            <Paper variant="outlined" className={ isPatient ? classes.patientDialog : classes.clinicDialog }>
              <Box display="inline-block" p={1}>
                <pre className={classes.paragraph}>{message}</pre>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Box>
  )
}