import { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import DayjsUtils from '@date-io/dayjs'
import { useTranslation } from 'react-i18next'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers'
import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core'
import { useHistory, useLocation } from 'react-router'

import route from '../api/routes'
import utils from '../utils'
import {
  vitalList,
} from '../libs/constants'

export default function VitalSignEnter() {
  const location = useLocation()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [sysdia, setSysDia] = useState(null)
  const [sys, setSys] = useState(null)
  const [dia, setDia] = useState(null)
  const [spo2, setSpo2] = useState(null)
  const [hstix, setHstix] = useState(null)
  const [temperature, setTemperature] = useState(null)
  const [weight, setWeight] = useState(null)
  const [imt, setImt] = useState(null)
  const [cigarette, setCigarette] = useState(null)
  const [aerobic, setAerobic] = useState(null)
  const [pulse, setPulse] = useState(null)
  const [dateTime, setDateTime] = useState(dayjs())
  const [remark, setRemark] = useState('')

  const [displayInstruction, setInstruction] = useState(null)

  const initVitals = useRef(false)

  const input1Regex = new RegExp(utils.getInputFieldConstant(1), 'g')
  const input2Regex = new RegExp(utils.getInputFieldConstant(2), 'g')
  const input3Regex = new RegExp(utils.getInputFieldConstant(3), 'g')
  const input4Regex = new RegExp(utils.getInputFieldConstant(4), 'g')

  if (!location.state?.code) {
    history.replace('/')
  }

  const emptyFields = () => {
    setSysDia('')
    setSys('')
    setDia('')
    setSpo2('')
    setHstix('')
    setTemperature('')
    setWeight('')
    setImt('')
    setCigarette('')
    setAerobic('')
    setDateTime(dayjs())
    setRemark('')
    setPulse('')
  }

  const submitHandler = async () => {
    const data = {
      sysdia,
      sys,
      dia,
      spo2,
      hstix,
      temperature,
      weight,
      imt,
      cigarette,
      aerobic,
      pulse,
      remark,
      at: dateTime.toISOString(),
    }

    try {
      await route.patients.uploadObservation(location.state.code, data)

      alert(t('success') + '!')
      emptyFields()
    } catch (err) {
      alert('Something gone wrong, please try again')
    }
  }

  function generateInstruction(templateList, instruction) {
    const template = templateList.find(s => s.id === instruction.templateId)
    if (template) {
      let englishContent = template.englishContent

      englishContent = englishContent.replace(input1Regex, instruction.value1)
      englishContent = englishContent.replace(input2Regex, instruction.value2)
      englishContent = englishContent.replace(input3Regex, instruction.value3)
      englishContent = englishContent.replace(input4Regex, instruction.value4)

      let chineseContent = template.chineseContent
      chineseContent = chineseContent.replace(input1Regex, instruction.value1)
      chineseContent = chineseContent.replace(input2Regex, instruction.value2)
      chineseContent = chineseContent.replace(input3Regex, instruction.value3)
      chineseContent = chineseContent.replace(input4Regex, instruction.value4)

      return {
        englishContent,
        chineseContent,
      }
    }

    return {
      englishContent: '',
      chineseContent: '',
    }
  }

  function fetchBookingVital() {
    if (!initVitals.current) {
      initVitals.current = true
      
      route.patients.bookingVitals(location.state.code).then(res => {
        const instruction = res.data.instruction
        const templates = res.data.template

        let display = {}

        vitalList.forEach((vitalInfo) => {
          const instru = instruction.find(s => s.vital === vitalInfo.key)
          if (instru) {
            display[vitalInfo.key] = generateInstruction(templates, instru)
          }
        })

        setInstruction(display)
      }).catch(err => {
        alert('Error occurs when getting information')
      })
    }
  }

  useEffect(() => {
    fetchBookingVital()
  })

  return (
    <Grid container spacing={4}>
      { displayInstruction?.sysdia && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              defaultValue={null}
              label={t('vitalSign.sysdia') + ' ' + ((i18n.language === 'en') ? displayInstruction.sysdia.englishContent : displayInstruction.sysdia.chineseContent)}
              value={sys}
              onChange={e => setSysDia(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.temperature && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.temperature') + ' ' + ((i18n.language === 'en') ? displayInstruction.temperature.englishContent : displayInstruction.temperature.chineseContent)}
              value={temperature}
              onChange={e => setTemperature(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.hstix && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.hstix') + ' ' + ((i18n.language === 'en') ? displayInstruction.hstix.englishContent : displayInstruction.hstix.chineseContent)}
              value={hstix}
              onChange={e => setHstix(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.spo2 && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.spo2') + ' ' + ((i18n.language === 'en') ? displayInstruction.spo2.englishContent : displayInstruction.spo2.chineseContent)}
              value={spo2}
              onChange={e => setSpo2(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.bodyweight && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.weight') + ' ' + ((i18n.language === 'en') ? displayInstruction.bodyweight.englishContent : displayInstruction.bodyweight.chineseContent)}
              value={weight}
              onChange={e => setWeight(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.imt && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.imt') + ' ' + ((i18n.language === 'en') ? displayInstruction.imt.englishContent : displayInstruction.imt.chineseContent)}
              value={imt}
              onChange={e => setImt(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.cigarette && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.cigarette') + ' ' + ((i18n.language === 'en') ? displayInstruction.cigarette.englishContent : displayInstruction.cigarette.chineseContent)}
              value={cigarette}
              onChange={e => setCigarette(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.aerobic && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.aerobic') + ' ' + ((i18n.language === 'en') ? displayInstruction.aerobic.englishContent : displayInstruction.aerobic.chineseContent)}
              value={aerobic}
              onChange={e => setAerobic(e.target.value)}
            ></TextField>
          </Grid>
        </>
      }
      { displayInstruction?.pulse && 
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              defaultValue={null}
              label={t('vitalSign.pulse') + ' ' + ((i18n.language === 'en') ? displayInstruction.pulse.englishContent : displayInstruction.pulse.chineseContent)}
              value={pulse}
              onChange={e => setPulse(e.target.value)}
            ></TextField>
          </Grid>
      </>
      }
      
      <MuiPickersUtilsProvider
        utils={DayjsUtils}
      >
        <Grid item xs={12} sm={3}>
          <DatePicker
            fullWidth
            autoOk
            inputVariant="outlined"
            variant="inline"
            format="YYYY-MM-DD"
            label={t('date')}
            value={dateTime}
            onChange={setDateTime}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TimePicker
            fullWidth
            autoOk
            inputVariant="outlined"
            variant="inline"
            format="HH:mm"
            label={t('time')}
            value={dateTime}
            onChange={setDateTime}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('remark')}
          value={remark}
          onChange={e => setRemark(e.target.value)}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={submitHandler}
        >{t('submit')}</Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="outlined"
          fullWidth
          onClick={() => { history.replace('/') }}
        >{t('back')}</Button>
      </Grid>
    </Grid>
  )
}
