import getAxios from './axios'

const list = () => getAxios({
  url: '/users',
  method: 'GET',
})

const create = (username, password, name, roles, activated) => getAxios({
  url: '/users',
  method: 'POST',
  data: {
    username,
    password,
    name,
    roles,
    activated,
  },
})

const show = (id) => getAxios({
  url: `/users/${id}`,
  method: 'GET',
})

const update = (id, username, password, name, roles, activated) => {
  const data = {
    username,
    name,
    roles,
    activated,
  }

  if (password) {
    data.password = password
  }

  return getAxios({
    url: `/users/${id}`,
    method: 'PATCH',
    data: data,
  })
}

const destroy = (id) => getAxios({
  url: `/users/${id}`,
  method: 'DELETE',
})

const getRooms = (id) => getAxios({
  url: `/users/${id}/rooms`,
  method: 'GET',
})

const updateRooms = (id, roomIds = []) => getAxios({
  url: `/users/${id}/rooms`,
  method: 'PATCH',
  data: {
    roomIds,
  },
})

const users = {
  list,
  create,
  show,
  update,
  destroy,
  getRooms,
  updateRooms,
}

export default users