import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  Box,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import BookmarkIcon from '@material-ui/icons/Bookmark'

import { routes } from '../api'

import PreparationList from './PreparationList'
import CautionList from './CautionList'

const useStyles = makeStyles({
  input: {
    border: '1px solid grey',
    borderRadius: '0.1em',
    padding: 0,
    margin: 0,
    fontSize: '5em',
    width: '100%',
    height: 'auto',
    textAlign: 'center',
  },
})

export default function CodeEnter(props) {
  const {
    onCodeSet,
    onBookingSet,
  } = props

  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const url = new URLSearchParams(location.search)
  const [code, setCode] = useState(url.get('code') || '')

  useEffect(() => {
    const savedCode = localStorage.getItem('code')
    if (savedCode) {
      setCode(savedCode)
    }
  }, [])

  function validateAndSaveCode() {
    if (code) {
      routes.patients.validate(code)
        .then(res => {
          localStorage.setItem('code', code)
          alert('ok!')
        })
        .catch(err => {
          console.log(err)
          alert(t('invalidCode'))
        })
    }
  }

  function submitHandler(e) {
    e.preventDefault()

    if (code) {
      routes.patients.booking(code)
        .then(res => {
          localStorage.setItem('code', code)
          onCodeSet(code)
          onBookingSet(res.data)
        })
        .catch(err => {
          console.log(err)
          alert(t('loginFailed'))
        })
    }
  }

  const goToEnterDataPage = () => {
    if (code) {
      routes.patients.booking(code)
        .then(() => {
          history.replace('/data', {
            code,
          })
        }).catch(err => {
          console.log(err)
          alert(t('loginFailed'))
        })
    }
  }

  const goToCheckBookingPage = () => {
    history.replace('/booking')
  }

  return (
    <Container>
      <Card variant="outlined">
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">{ t('enterCode') }</Typography>
              <form noValidate onSubmit={submitHandler}>
                <Box display="flex" justifyContent="center" mx={3} pt={3}>
                  <input
                    value={code}
                    className={classes.input}
                    onChange={e => setCode(e.target.value.toUpperCase())}
                  ></input>
                </Box>
                <Box display="flex" justifyContent="center" p={3}>
                  <Grid container>
                    <Grid item xs={12} md={3} lg={3}>
                      <Box display="flex" justifyContent="center" p={3}>
                        <Button
                          margin="normal"
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={!code.length}
                        >{ t('startConversation') }</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <Box display="flex" justifyContent="center" p={3}>
                        <Button
                            margin="normal"
                            color="primary"
                            variant="contained"
                            disabled={!code.length}
                            onClick={goToEnterDataPage}
                        >{ t('submitData') }</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <Box display="flex" justifyContent="center" p={3}>
                        <Button
                            margin="normal"
                            color="primary"
                            variant="contained"
                            onClick={goToCheckBookingPage}
                        >{ t('checkBooking') }</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <Box display="flex" justifyContent="center" p={3}>
                        <Button
                            margin="normal"
                            style={{
                              backgroundColor: '#3fb569',
                            }}
                            variant="contained"
                            onClick={validateAndSaveCode}
                        >{ t('saveCode') }</Button>
                        {/* <IconButton
                          onClick={validateAndSaveCode}
                        >
                          <BookmarkIcon sx={{ fontSize: '5em' }} />
                        </IconButton> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <PreparationList />
              <Box mb={3}></Box>
            </Grid>
            <Grid item xs={12}>
              <CautionList />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Container>
  )
}