import React, { useRef, useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Card,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  LinearProgress,
  List,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import PublishIcon from '@material-ui/icons/Publish'
import RefreshIcon from '@material-ui/icons/Refresh'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import api from '../../api'

import MaterialItem from './MaterialItem'

function MaterialItems(props) {
  const {
    loading,
    materials,
    onDelete,
    onMoveUp,
    onMoveDown,
  } = props

  return <List>{
    materials.map((m, i) => (
      <MaterialItem
        key={m.id}
        loading={loading}
        item={m}
        onDelete={() => onDelete(m.id, i)}
        onMoveUp={() => onMoveUp(m.id, i)}
        onMoveDown={() => onMoveDown(m.id, i)}
      />
    ))
  }</List>
}

export default function MaterialList(props) {
  const {
    group,
    onCloseClick,
  } = props

  const [title, setTitle] = useState(group.title)
  const [uploadType, setUploadType] = useState('text')
  const [uploadTitle, setUploadTitle] = useState('')
  const [uploadTextContent, setUploadTextContent] = useState('')
  const [previewContent, setPreviewContent] = useState('')
  const uploadFileInputRef = useRef(null)

  const init = useRef(false)
  const [loading, setLoading] = useState(false)
  const [materials, setMaterials] = useState([])
  const [error, setError] = useState(false)

  async function refresh() {
    try {
      setLoading(true)
      const { data } = await api.routes.materials.list(group ? group.id : 0, true)
      setMaterials(data)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  function onTypeChangeHandler(e) {
    setUploadTextContent('')
    setPreviewContent('')
    setUploadType(e.target.value)
  }

  function onSelectClick() {
    uploadFileInputRef.current.click()
  }

  function onFileInputChange(e) {
    if (uploadFileInputRef.current.files && uploadFileInputRef.current.files[0]) {
      var reader = new FileReader()

      reader.onload = function (e) {
        setPreviewContent(e.target.result)
      }

      reader.readAsDataURL(uploadFileInputRef.current.files[0])
    }
  }

  async function onUploadClick() {
    const sortOrder = materials ? materials.length : 0

    try {
      const createResponse = await api.routes.materials.create(
        group.id,
        uploadType,
        uploadTitle,
        uploadTextContent,
        sortOrder,
      )

      if (uploadType === 'image') {
        const formData = new FormData()
        formData.append('file', uploadFileInputRef.current.files[0])
        await api.routes.materials.uploadFile(createResponse.data.id, formData)
      }

      setUploadTextContent('')
      setPreviewContent('')
      refresh()
    } catch (err) {
      // TODO error handling
    }
  }

  async function onDeleteHandler(id) {
    try {
      if (window.confirm('Are you sure to remove this material?')) {
        await api.routes.materials.destroy(id)
        setUploadTextContent('')
        setPreviewContent('')
        refresh()
      }
    } catch (err) {
      // TODO error handling
    }
  }

  async function onMoveUpHandler(id, index) {
    if (index === 0) {
      return
    }

    const indexMaterial = materials[index]
    const beforeMaterial = materials[index - 1]

    await api.routes.materials.update(indexMaterial.id, {
      sortOrder: beforeMaterial.sortOrder,
    })

    await api.routes.materials.update(beforeMaterial.id, {
      sortOrder: indexMaterial.sortOrder,
    })

    refresh()
  }

  async function onMoveDownHandler(id, index) {
    if (index === materials.length - 1) {
      return
    }

    const indexMaterial = materials[index]
    const afterMaterial = materials[index + 1]

    await api.routes.materials.update(indexMaterial.id, {
      sortOrder: afterMaterial.sortOrder,
    })

    await api.routes.materials.update(afterMaterial.id, {
      sortOrder: indexMaterial.sortOrder,
    })

    refresh()
  }

  const updateTitle = async () => {
    try {
      await api.routes.materialGroups.update(group.id, title)
    } catch (e) {
      alert('Error occurs when updating the material group title')
    }
  }

  const uploadable = () => {
    return (!uploadTextContent && !previewContent) || !uploadTitle
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true
      refresh()
    }
  })

  if (!group) {
    return false
  }

  return (
    <>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <Typography
            variant="h5"
            style={{ flex: '1 1 100%' }}
          >{group.name} Materials</Typography>
          <IconButton style={{ color: 'white' }} onClick={refresh}>
            <RefreshIcon />
          </IconButton>
          <IconButton style={{ color: 'white' }} onClick={() => onCloseClick()}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <Box p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        placeholder="Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                      ></TextField>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<AddCircleIcon />}
                        disabled={!title.length}
                        onClick={updateTitle}
                      >Update Material Group Title</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <Box p={3}>
                  <Grid container spacing={3} alignItems="flex-end">
                    <Grid item xs={6} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel>Material Content Type</InputLabel>
                        <Select
                          value={uploadType}
                          onChange={onTypeChangeHandler}
                        >
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="image">Image</MenuItem>
                          <MenuItem value="youtube_link">Youtube Link</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onUploadClick}
                        startIcon={<PublishIcon />}
                        disableElevation
                        disabled={uploadable()}
                      >Upload</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={uploadTitle}
                        label="Material Title"
                        onChange={e => setUploadTitle(e.target.value)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12}>
                      {uploadType === 'text' &&
                        <TextField
                          multiline
                          fullWidth
                          rows={3}
                          variant="outlined"
                          value={uploadTextContent}
                          onChange={(e) => setUploadTextContent(e.target.value)}
                        ></TextField>
                      }
                      {uploadType === 'image' && (
                        <>
                          <input
                            hidden
                            type="file"
                            accept="image"
                            ref={uploadFileInputRef}
                            onChange={onFileInputChange}
                          />
                          <Box display="flex" alignItems="end">
                            <Box display="flex" mr={3} alignItems="end">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={onSelectClick}
                                startIcon={<AddIcon />}
                                disableElevation
                              >Select Image</Button>
                            </Box>
                            <img
                              src={previewContent}
                              alt=""
                              style={{ maxHeight: 150, maxWidth: 150 }}
                            />
                          </Box>
                        </>
                      )}
                      {uploadType === 'youtube_link' &&
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={uploadTextContent}
                          onChange={(e) => setUploadTextContent(e.target.value)}
                        ></TextField>
                      }
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              {!!error &&
                <Box my={3}>
                  <Alert severity="error">Error occurs when fetching material list</Alert>
                </Box>
              }
              {materials &&
                <Card variant="outlined">
                  {loading && <LinearProgress />}
                  <Grid container>
                    <Grid item xs>
                      <MaterialItems
                        loading={loading}
                        materials={materials}
                        onDelete={onDeleteHandler}
                        onMoveUp={onMoveUpHandler}
                        onMoveDown={onMoveDownHandler}
                      />
                      <Box pt={3}></Box>
                    </Grid>
                  </Grid>
                </Card>
              }
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}