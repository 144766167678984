import auth from './auth'
import rooms from './rooms'
import bookings from './bookings'
import bookingRemarks from './booking_remark'
import patients from './patients'
import users from './users'
import materials from './materials'
import materialGroups from './material_groups'
import observationCharts from './observation_charts'
import observations from './observations'
import cancelReasons from './cancel_reason'
import vitalTemplates from './vital_template'
import bookingVitals from './booking_vital'
import surveys from './survey'
import summarys from './summary'
import leaflets from './leaflet'
import consultationReasons from './consultation_reason'
import groupEducations from './group_education'
import getAxios from './axios'

const routes = {
  auth,
  rooms,
  bookings,
  bookingRemarks,
  patients,
  users,
  materials,
  materialGroups,
  observationCharts,
  observations,
  cancelReasons,
  vitalTemplates,
  bookingVitals,
  surveys,
  summarys,
  leaflets,
  consultationReasons,
  groupEducations,
  getAxios,
}

export default routes