const en = {
  translation: {
    lang: 'en',
    title: 'Online Clinics',

    submit: 'Submit',
    gender: 'Gender',
    dob: 'Day of Birth',
    male: 'Male',
    female: 'Female',
    date: 'Date',
    time: 'Time',
    remark: 'Remark',
    success: 'Success',
    back: 'Back',
    cancel: 'Cancel',

    surveytitle: 'Survey',

    // Home
    startConversation: 'Start Conversation',
    submitData: 'Submit Data',
    checkBooking: 'Check Appointment',
    saveCode: 'Save Code',

    // PatientQRCodeDialog
    ntwcOnlineClinic: 'online clinic',
    futureUse: 'For future use only. Need to enter the five figure code.',
    linkScan: 'Please scan the QR Code to start the consultation',
    bookingDate: 'Appointment Date',
    bookingTime: ',Time',
    qrnotwork: 'If the QR Code is not working, use the following link',
    firstOnline: 'First follow-up consultation online',

    // PatientLogin
    noAppointment: 'Input data not correct or no appointment found. Please check your input data and the appointment time. Thank You!',

    // CodeEnter
    enterCode: 'Please enter the code to start the process.',
    loginFailed: 'No appointment found. Either the appointment is expired or the appointment day is not today. \nPlease check the appointment date and try to come back on time. Thank You!',
    nvalidCode: 'Invalid Code',

    // Info Enter
    enterInfo: 'Please enter phone number and hn to search your appointment records.',
    infoFailed: 'No appointment found.',
    phone: 'Phone Number',
    hn: 'HN Number',
    confirm: 'Confirm',

    // Patient Booking
    patientBooking: {
      title: 'Here is your appointment',
      lastname: 'Last Name',
      bookingDate: 'Appointment Date',
      bookingTime: 'Appointment Time',
      conversationType: 'Conversation Type',
      status: 'Status',
    },

    // Preparation List
    preparationList: {
      title: 'Preparations',
      first: 'Ensure that there is a stable network. If there is a video interruption, we will contact the patient again. If the contact fails, we will call the patient by phone.',
      second: 'Ensure the consultation is conducted in a quiet and confidential environment to avoid leakage of patient information.',
      third: 'Please prepare the patient follow up appointment slip and the name of medication taken by patient.',
      fourth: 'Please prepare a copy or the original patient’s ID card for verification of patient authentication.',
    },

    // Precautions
    precautions: {
      title: 'Precautions',
      1: 'The on-line clinic is free of charge.',
      2: 'Recording is prohibited during the video conversation.',
      3: 'The information collected by the on-line clinic will only be used for medical purposes.',
      4: 'Please log in to the system at least 5 minutes before the appointment time and wait for us to meet you.',
      5: 'If 15 minutes are overdue, the appointment will be automatically cancelled.',
      6: 'Each video consultation time will be limited to 30 minutes ( including late arrival time).',
      7: 'The on-line clinic service does not provide medical examination, drug prescription or sick leave certificates.',
    },

    vitalSign: {
      sysdia: 'Blood Pressure(mmHg)(Format: XX/XX)',
      map: 'Mean Arterial Blood Pressure(mmHg)',
      temperature: 'TEMPERATURE(°C)',
      hstix: 'Hypoglycemia(mmol/L)',
      spo2: 'SPO2(%)',
      weight: 'BODY WEIGHT(kg)',
      imt: 'Inspiratory Muscle Training(cmH2O)',
      cigarette: 'CIGARETTE PER DAY',
      aerobic: 'AEROBIC EXERCISE',
      pulse: 'PULSE',
    },

    patientLogin: {
      hello: 'Hello',
      verify_identity: 'Please enter the following information to verify your identity',
      first_four_digit: 'HKID First 4 Digit',
      year: 'Birth Year',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      enter: 'Enter',
    },

    survey: {
      score: {
        highlyagree: 'Highly Agree',
        agree: 'Agree',
        normal: 'Normal',
        disagree: 'Disagree',
        highlydisagree: 'Highly Disagree',
      },
      question: {
        topic: 'To enhance our quality, we sincerely invite you to complete this survey',
        1: 'I was satisfied with this online service',
        2: 'I was satisfied with the nurse',
        3: 'Online Clinic system is convenient and easy to use',
      },
      finished: 'Thank you for your feedback!',
      missinganswer: 'Please fill in all answers.',
    },

    leaflet: {
      next: 'Next Leaflet',
      dont_want_see: 'Close',
    },
  },
}

export default en