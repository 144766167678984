import React from 'react'
import {
  Fade,
  Box,
} from '@material-ui/core'

export default function TabPanel({ children, value, index }) {
  if (value !== index) {
    return false
  }

  return (
    <Fade in={value === index}>
      <Box elevation={0}>
        {children}
      </Box>
    </Fade>
  )
}