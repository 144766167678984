import React from 'react'
import {
  Grid,
  Box,
} from '@material-ui/core'

import NumberPicker from './../NumberPicker'

function BirthdayVerify({
    year, setYear,
    month, setMonth,
    day, setDay,
}) {
    return (
        <>
        <Grid item xs={12}>
            <Box display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
                <NumberPicker
                    from={1900}
                    to={2020}
                    label="Year 年"
                    value={year}
                    native
                    onChange={e => setYear(e.target.value)}
                />
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
            <NumberPicker
                from={1}
                to={12}
                label="Month 月"
                value={month}
                native
                onChange={e => setMonth(e.target.value)}
            />
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box display="flex" alignItems="center">
            <NumberPicker
                from={1}
                to={31}
                label="Day 日"
                value={day}
                native
                onChange={e => setDay(e.target.value)}
            />
            </Box>
        </Grid>
        </>
    )
}

export default BirthdayVerify