import 'webrtc-adapter'
import 'fontsource-roboto'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translate from './i18n'

import store from './store'
import api from './api'
import actions from './store/actions'

import App from './App'

i18n
  .use(initReactI18next)
  .init({
    resources: translate,
    lng: 'cht',
    fallbackLng: 'en',
  })

api.routes.auth.me().then(({ data }) => {
  store.dispatch(actions.login(data.id, data.username, data.name, data.roles))
}).catch(err => { }).finally(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
