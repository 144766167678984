import React from 'react'
import { connect } from 'react-redux'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles({
    container: {
        maxHeight: 440,
    },
})

function ReasonTable(props) {
    const {
        reasons,
        onDeleteClick,
        onReasonEditClick,
    } = props
    const classes = useStyles()
    if (!reasons) return false

    const rows = reasons.map((reason, index) => {
        return (
            <TableRow key={reason.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{reason.content}</TableCell>
                <TableCell>{reason.reason_type}</TableCell>
                <TableCell>{reason.order_number}</TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => onReasonEditClick(reason)} color="primary" size="small">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteClick(reason)} color="secondary" size="small">
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    })

    return (
        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="center">Content</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Order Number</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      )
}

export default connect(state => ({
    roles: state.user.roles,
}))(ReasonTable)