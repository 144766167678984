import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'

import { routes, ws } from '../../../api'
import { bookingStatus } from '../../../libs/constants'
import utils from '../../../utils'
import useWebSocket from '../../../hooks/useWebSocket'
import useStream from '../../../hooks/useStream'
import usePeerConnection from '../../../hooks/usePeerConnection'

import Messenger from '../../Messenger/Messenger'
import VitalRecords from '../VitalRecords'
import DialogContent from '../../DialogContent'

const useStyles = makeStyles({
  video: {
    display: 'block',
    width: '100%',
    backgroundColor: '#000',
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function VideoBox(props) {
  const { stream, muted } = props
  const classes = useStyles()
  const videoRef = useRef(null)

  useEffect(() => {
    init()
  })

  function init() {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream
    }
  }

  return <video
    ref={videoRef}
    className={classes.video}
    autoPlay
    playsInline
    muted={muted}
  ></video>
}

const MemoVideoBox = React.memo(VideoBox)

export default function ChatRoom(props) {
  const {
    room,
    sender,
    receiver,
    iceServersConfig,
    toPatientTable,
    messages,
    sendMessage,
  } = props

  const {
    subscribeOnMessage,
    unsubscribeOnMessage,
    sendWebsocketMessage,
  } = useWebSocket()
  const {
    getStream,
    openStream,
    muteAudio,
    closeStream,
  } = useStream()
  const {
    getPeerConnection,
    remoteStream,
    initPeerConnection,
    closePeerConnection,
    subscribeOnConnectionStateChange,
    unsubscribeOnConnectionStateChange,
    subscribeOnIceConnectionStateChange,
    unsubscribeOnIceConnectionStateChange,
    subscribeOnNegotiationNeeded,
    unsubscribeOnNegotiationNeeded,
    addIceCandidate,
  } = usePeerConnection()
  const initialized = useRef(false)
  const [openVitalRecords, setOpenVitalRecords] = useState(false)
  const [muted, setMuted] = useState(false)

  useEffect(() => {
    subscribeOnMessage('chatRoom', onSocketMessage)
    subscribeOnConnectionStateChange('chatRoom', onConnectionStateChange)
    subscribeOnIceConnectionStateChange('chatRoom', onIceConnectionStateChange)
    subscribeOnNegotiationNeeded('chatRoom', onNegotiationNeeded)

    if (!initialized.current) {
      init()
    }

    return () => {
      unsubscribeOnMessage('chatRoom')
      unsubscribeOnConnectionStateChange('chatRoom')
      unsubscribeOnIceConnectionStateChange('chatRoom')
      unsubscribeOnNegotiationNeeded('chatRoom')
    }
  })

  const init = async () => {
    initialized.current = true
    await openStream()

    if (getStream() && !getPeerConnection()) {
      initPeerConnection(iceServersConfig, getStream(), sender, receiver.code)
      sendWebsocketMessage(ws.newSendInvitationIntent(sender, receiver.code))
    }
  }

  const onSocketMessage = (e) => {
    switch (e.intent) {
      case ws.intents.sendReply:
        createOffer()
        break
      case ws.intents.sendAnswer:
        getPeerConnection().setRemoteDescription(new RTCSessionDescription(e.data.answer))
        break
      case ws.intents.sendIceCandidate:
        addIceCandidateToPeer(new RTCIceCandidate(e.data.iceCandidate))
        break
      default:
    }
  }

  const createOffer = async (restart) => {
    const constrain = {
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
    }

    if (restart) {
      constrain.iceRestart = true
    }

    getPeerConnection().createOffer(constrain).then(offer => {
      return getPeerConnection().setLocalDescription(new RTCSessionDescription(offer))
    }).catch(err => {
      console.error(err)
    })

    setTimeout(() => {
      if (getPeerConnection() && getPeerConnection().connectionState !== 'connected') {
        createOffer(true)
      }
    }, 5000)
  }

  const sendOffer = () => {
    if (!getPeerConnection().localDescription) {
      return setTimeout(sendOffer, 250)
    }

    sendWebsocketMessage(ws.newSendOfferIntent(sender, receiver.code, getPeerConnection().localDescription))
  }

  const addIceCandidateToPeer = (iceCandidate) => {
    if (!getPeerConnection().remoteDescription) {
      return setTimeout(() => {
        addIceCandidateToPeer(iceCandidate)
      }, 250)
    }

    addIceCandidate(iceCandidate)
  }

  const onConnectionStateChange = (state) => {
    switch (state) {
      case 'connected':
        setTimeout(() => utils.setMaxBitRate(getPeerConnection()), 2000)
        break
      case 'disconnected':
      case 'failed':
      case 'closed':
        closeAll()
        break
      default:
    }
  }

  const onIceConnectionStateChange = (state) => {
    switch (state) {
      case 'failed':
        createOffer(true)
        break
      default:
    }
  }

  const onNegotiationNeeded = () => {
    sendOffer()
  }

  const closeAll = () => {
    closeStream()
    closePeerConnection()
    toPatientTable()
  }

  const putOnHold = () => {
    sendWebsocketMessage(ws.newPutOnHoldIntent(sender, receiver.code))
    closeAll()
  }

  const muteMyself = () => {
    const isMuted = !muted
    setMuted(isMuted)
    muteAudio(isMuted)
  }

  const viewVitalRecords = () => {
    setOpenVitalRecords(true)
  }

  function showSurvey() {
    sendWebsocketMessage(ws.newShowSurveyIntent(sender, receiver.code))
  }

  async function finishConsultation() {
    if (window.confirm('Are you confirm you have done the inspection with this patient?')) {
      try {
        await routes.bookings.update(receiver.id, {
          status: bookingStatus.seen,
        })
        sendWebsocketMessage(ws.newDoneInspectionIntent(sender, receiver.code))
        closeAll()
      } catch (err) {
        // TODO error handling
      }
    }
  }

  if (!receiver) {
    return false
  }

  return (
    <Grid container>
      <Grid item xs>
        <Paper variant="outlined" square>
          <MemoVideoBox stream={remoteStream} />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Card variant="outlined">
          <MemoVideoBox stream={getStream()} muted />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>SOPD: {receiver.sopd}</Grid>
              <Grid item xs={12}>Name: {receiver.name}</Grid>
              <Grid item xs={12}>DoB: {receiver.dob}</Grid>
              <Grid item xs={12}>Gender: {receiver.gender.toUpperCase()}</Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Box width="100%">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={viewVitalRecords}
                    fullWidth
                    size="small"
                  >Vital Records</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={putOnHold}
                    fullWidth
                    size="small"
                  >On Hold</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={muteMyself}
                    fullWidth
                    size="small"
                  >{muted ? 'Unmute Myself' : 'Mute Myself'}</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={showSurvey}
                    fullWidth
                    size="small"
                  >Show Survey</Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={finishConsultation}
                    fullWidth
                    size="small"
                  >Finish Consultation</Button>
                </Grid>
              </Grid>
            </Box>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Messenger
          sender={sender}
          receiver={receiver.code}
          color={room.color}
          messages={messages[receiver.code]}
          onSendMessage={sendMessage}
        />
      </Grid>

      <Dialog
        open={!!openVitalRecords}
        onClose={() => setOpenVitalRecords(false)}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
      >
        <DialogContent
          title={`Vital Records (${receiver.name})`}
          onClose={() => setOpenVitalRecords(false)}
        >
          <VitalRecords hn={receiver.sopd} />
        </DialogContent>
      </Dialog>
    </Grid>
  )
}