import getAxios from './axios'

const get = (id) => getAxios({
    url: `/reasons/${id}`,
    method: 'GET',
})

const list = () => getAxios({
    url: '/reasons',
    method: 'GET',
})

const create = (userId, userName, content) => getAxios({
    url: '/reasons',
    method: 'PUT',
    data: {
        userId,
        userName,
        content,
    },
})

const update = (reasonId, userId, userName, content) => getAxios({
    url: `/reasons/${reasonId}`,
    method: 'PUT',
    data: {
        userId,
        userName,
        content,
    },
})

const remove = (reasonId) => getAxios({
    url: `/reasons/${reasonId}`,
    method: 'DELETE',
})

const cancelReasons = {
    get,
    list,
    create,
    update,
    delete: remove,
}

export default cancelReasons