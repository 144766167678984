import axios from 'axios'
import getAxios from './axios'

import store from '../../store'
import actions from '../../store/actions'
import location from '../location'

const get = (id) => getAxios({
    url: `/leaflets/${id}`,
    method: 'GET',
})

const list = () => getAxios({
    url: '/leaflets',
    method: 'GET',
})

const fetch = () => getAxios({
    url: '/leafletlist',
    method: 'GET',
})

const rand = () => getAxios({
    url: '/nextleaflet',
    method: 'GET',
})

const upload = (label, file) => {
    const formData = new FormData()
    formData.append('label', label)
    formData.append('file', file)

    const instance = axios.create({
        baseURL: location.backendURL(),
        timeout: 1000,
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
    })

    instance.interceptors.response.use(response => response, error => {
        switch (error.response.status) {
            case 401:
            store.dispatch(actions.logout())
            break
            default:
        }

        return Promise.reject(error)
    })    

    return instance({
        method: 'POST',
        url: '/leaflets',
        data: formData,
    })
}

const remove = (leafletId) => getAxios({
    url: `/leaflets/${leafletId}`,
    method: 'DELETE',
})

const leaflets = {
    get,
    list,
    fetch,
    rand,
    upload,
    delete: remove,
}

export default leaflets