import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    makeStyles,
    Container,
    Grid,
    Typography,
    Button,
    Card,
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableContainer,
} from '@material-ui/core'

import BookingStatus from './AdminRoom/Schedules/BookingStatus'

const useStyles = makeStyles({
    container: {
      maxHeight: 440,
    },
  })

function PatientBookingList(props) {
    const {
        bookingList,
    } = props
    const classes = useStyles()
    const history = useHistory()

    const { t } = useTranslation()

    const rows = bookingList.map((booking, index) => {
        return (
            <TableRow key={booking.code}>
                <TableCell>{index + 1}</TableCell>
                <TableCell align="center">
                    {booking.last_name}
                </TableCell>
                <TableCell align="center">
                    {dayjs(booking.time).format('YYYY-MM-DD')}
                </TableCell>
                <TableCell align="center">
                    {dayjs(booking.time).format('HH:mm')}
                </TableCell>
                <TableCell align="center">
                    {booking.conversationType}
                </TableCell>
                <TableCell align="center">
                    <BookingStatus booking={booking} />
                </TableCell>
            </TableRow>
        )
    })

    return (
        <Container>
            <Card variant="outlined">
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Typography variant="h4" align="center">{t('patientBooking.title')}</Typography>
                        <Grid item xs={12} md={12}>
                            <Paper variant="outlined">
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell align="center">{t('patientBooking.lastname')}</TableCell>
                                                <TableCell align="center">{t('patientBooking.bookingDate')}</TableCell>
                                                <TableCell align="center">{t('patientBooking.bookingTime')}</TableCell>
                                                <TableCell align="center">{t('patientBooking.conversationType')}</TableCell>
                                                <TableCell align="center">{t('patientBooking.status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" justifyContent="center" p={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => { history.replace('/') }}
                    >{t('back')}</Button>
                </Box>
            </Card>
        </Container>
    )
}

export default PatientBookingList