import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Paper,
  Grid,
  Typography,
  Box,
  Container,
} from '@material-ui/core'

import actions from '../store/actions'
import ColoredButton from '../components/ColoredButton'


function Done({ patient, room, dispatch }) {
  const history = useHistory()
  const [patientName, setPatientName] = useState('')
  const [roomName, setRoomName] = useState('')
  const [color, setColor] = useState('')
  const [surveyLink, setSurveyLink] = useState('')
  const updating = useRef(false)

  useEffect(() => {
    if (!updating.current) {
      updating.current = true
      keep()
    }

    if (!patient.name && !patientName) {
      history.push('/')
    }
  })

  function keep() {
    setPatientName(patient.name)
    setRoomName(room.name)
    setColor(room.color)
    setSurveyLink(room.surveyLink)
    dispatch(actions.logout())
  }

  function enterSurvey() {
    if (surveyLink) {
      window.location = surveyLink
    }
  }

  return (
    <Container>
      <Paper variant="outlined">
        <Box p={3}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1" align="center" style={{color: color}}>{ roomName }</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">今次評估已經完成，謝謝 {patientName} 您的合作!</Typography>
              { surveyLink &&
                <Typography variant="h5" align="center">離開之前，我們誠邀您完成以下問卷。</Typography>
              }
            </Grid>
            { surveyLink &&
              <Grid item xs={12}>
                <Typography variant="h4">
                  <Box display="flex" justifyContent="center" mt={2} alignItems="center">
                    <ColoredButton
                      color={color}
                      textColor="white"
                      size="large"
                      onClick={enterSurvey}
                    >
                      <Typography variant="h4">進入問卷 Enter Survey</Typography>
                    </ColoredButton>
                    <Box ml={1}>
                      <span> 謝謝!!</span>
                    </Box>
                  </Box>
                </Typography>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
    </Container>
  )
}

export default connect(state => ({
  room:  state.room,
  patient: state.patient,
}))(Done)
