import excel from './excel'
import constants from './constant'

const getPatientURL = (code) => {
  return document.location.origin + `?code=${code}`
}

const setMaxBitRate = (peerConnection, maxBitrate = 250_000) => {
  if (!peerConnection) {
    return
  }

  if (!maxBitrate || !Number.isInteger(maxBitrate)) {
    maxBitrate = 500_000
  }

  if (
    'RTCRtpSender' in window &&
    'setParameters' in window.RTCRtpSender.prototype
  ) {
    peerConnection.getSenders().forEach((sender) => {
      if (sender.track.kind === 'audio') {
        return
      }

      const parameters = sender.getParameters()

      if (!parameters.encodings) {
        parameters.encodings = [{}]
      }

      if (parameters.encodings.length === 0) {
        parameters.encodings.push({})
      }

      if (parameters.encodings[0].maxBitrate === maxBitrate) {
        return
      }

      parameters.encodings[0].maxBitrate = maxBitrate
      parameters.encodings[0].maxFramerate = 30

      sender.setParameters(parameters)
        .catch(e => console.error(e))
    })
  }
}

function isRole(roles, roleName) {
  return !!roles.find(role => role === roleName)
}

function isSuper(roles) {
  return isRole(roles, 'super')
}

function isAdmin(roles) {
  return isRole(roles, 'admin')
}

function isNormal(roles) {
  return isRole(roles, 'normal')
}

function getInputFieldConstant(i) {
  return `{InputField${i}}`
}

function getInputFieldRegez() {
  return '{InputField[0-9]+}'
}

const utils = {
  getPatientURL,
  setMaxBitRate,
  isSuper,
  isAdmin,
  isNormal,
  getInputFieldConstant,
  getInputFieldRegez,
  excel,
  constants,
}

export default utils